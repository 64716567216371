import { Auth } from 'aws-amplify';

export async function signUp(email, password) {
    try {
        const { user } = await Auth.signUp({
            email,
            password,
            attributes: {
                email,          // optional
            }
        });
        console.log(user);
    } catch (error) {
        console.log('error signing up:', error);
    }
}

export async function signIn(email, password) {
    try {
        const user = await Auth.signIn(email, password);
        return user;
    } catch (error) {
        console.log('error signing in', error);
        return false;
    }
}

export async function resendConfirmationCode(email) {
    try {
        await Auth.resendSignUp(email);
        console.log('code resent successfully');
    } catch (err) {
        console.log('error resending code: ', err);
    }
}

export async function signOut() {
    try {
        await Auth.signOut();
    } catch (error) {
        console.log('error signing out: ', error);
    }
}