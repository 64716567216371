import React from 'react';
import { Page, Document, Image, StyleSheet } from '@react-pdf/renderer';
import RegisterTitle from "../general/RegisterTitle";
import CompanyInfo from "./Components/CompanyInfo";
import ShareItemsTable from "./Components/ShareItemsTable";

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
  });
  
  const ShareRegister = ({shares}) => (
            <Document>
                <Page size="A4" style={styles.page} orientation="landscape">
                    <RegisterTitle title='Share Register'/>
                    <CompanyInfo company={shares}/>
                    <ShareItemsTable shares={shares} />
                </Page>
            </Document>
        );
  
  export default ShareRegister