import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import 'assets/css/argon-dashboard-react.css';
import 'assets/css/custom.css';

import Amplify from 'aws-amplify';
import aws_config from './aws-exports';
import config from "./config";
import {BrowserRouter,Route,Switch,Redirect} from "react-router-dom";
import AuthLayout from "./App/layout/Auth";
import {App} from "App/index";
import {applyMiddleware, createStore} from "redux";

import thunkMiddleware from "redux-thunk";
import {createLogger} from "redux-logger";
import reducer from "./store/reducer";
import {Provider} from "react-redux";

Amplify.configure(aws_config);

const loggerMiddleware = createLogger();

const store = createStore(
    reducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
);

const app = (
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            {/* basename="/datta-able" */}
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
