import React, {useState, useEffect, PureComponent} from "react";
import { API, Storage } from 'aws-amplify';

import {listDocumentsAndFiles} from "../graphql/custom_queries";
import Header from "../components/Headers/Header";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, DropdownItem, DropdownMenu, DropdownToggle,
    Media, Nav, Progress,
    Row,
    Table, UncontrolledDropdown,
    UncontrolledTooltip
} from "reactstrap";

import { pdfjs, Document, Page, } from 'react-pdf';
import {pdf} from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import ShareCertificate from "../templates/ShareCertificate/ShareCertificate";
import shareCertificateDetails from "../templates/ShareCertificate/data/shareCertificateDetails";
import CapTable from "../templates/CapTable/CapTable";
import invoice from "../templates/CapTable/data/invoice";
import DirectorRegister from "../templates/DirectorRegister/DirectorRegister";

class Templates extends React.Component {

    state = {selectedTemplateTitle: ""}

    constructor(props) {
        super(props);

        this.showShareCertificateTemplate = this.showShareCertificateTemplate.bind(this);
        this.showCapTableTemplate = this.showCapTableTemplate.bind(this);
    }

    componentDidMount() {
        this.showShareCertificateTemplate();
    }

    showShareCertificateTemplate(){
        const doc = (<ShareCertificate shareCertificateDetails={shareCertificateDetails}/>);

        pdf(doc).toBlob().then(r => {
            this.setState({
                file: r,
                selectedTemplateTitle: "Share Certificate"
            });
        });
    }

    showCapTableTemplate(){
        const doc = (<CapTable invoice={invoice}/>);

        pdf(doc).toBlob().then(r => {
            this.setState({
                file: r,
                selectedTemplateTitle: "Cap Table"
            });
        });
    }

    showDirectorTableTemplate(){
        const doc = (<DirectorRegister invoice={invoice}/>);

        pdf(doc).toBlob().then(r => {
            this.setState({
                file: r,
                selectedTemplateTitle: "Director Register"
            });
        });
    }

    render() {
        return (
            <>
                <Header />
                {/* Page content */}
                <Container className=" mt--7" fluid>
                    <Row>
                        <Col>
                            <div className=" col">
                                <Card className=" shadow">
                                    <CardHeader className=" bg-transparent">
                                        <Row className="align-items-center">
                                            <Col xs="8">
                                                <h3 className="mb-0">HTML Templates</h3>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Table className="align-items-center table-flush" responsive hover>
                                            <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Template Name</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr onClick={() => this.showShareCertificateTemplate()}>
                                                <td>Share Certificate</td>
                                            </tr>
                                            <tr onClick={() => this.showCapTableTemplate()}>
                                                <td>Cap Table</td>
                                            </tr>
                                            <tr onClick={() => this.showDirectorTableTemplate()}>
                                                <td>Director Table</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                        <Col>
                            <Card>
                                <CardHeader>
                                    Preview
                                </CardHeader>
                                <CardBody>
                                    {this.state.file &&
                                    <div>
                                        <Document file={this.state.file}>
                                            <Page pageNumber={1} />
                                        </Document>
                                    </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}


pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PDFViewer = ({ children }) => {
    const [pdfUrl, setPdfUrl] = useState(null)

    useEffect(() => {
        const child = React.Children.only(children)

        pdf(child).toBlob().then(blob => {
            setPdfUrl(URL.createObjectURL(blob))
        })
    }, [children])

    return (
        <Document file={pdfUrl}>
            <Page renderMode='svg' pageNumber={1} />
        </Document>
    )
}

export default Templates;