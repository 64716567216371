import {API, Storage} from "aws-amplify";
import {getDocument, listCompanyDetailss} from "../graphql/queries";
import {
    createCompanyDetails,
    createDocument as createDocumentMutation,
    createFile as createFileMutation
} from "../graphql/mutations";
import {v4 as uuidv4} from "uuid";

export const awsInteraction = {
    getDocumentStatus,
    createDocument,
    fetchCompanyDetails,
    createFile: uploadUserFile
}

async function getDocumentStatus(documentID){
    const apiData = await API.graphql({
        query: getDocument,
        variables: {
            id: documentID
        }
    });

    const DocumentFromAPI = apiData.data.getDocument;
    let files = DocumentFromAPI.files.items;

    if(files.length > 0) {
        return files[files.length - 1].status;
    } else {
        return "Missing";
    }
}

async function createDocument(documentName, documentDescription){
    try{
        let doc = await API.graphql({
            query: createDocumentMutation,
            variables: {input: {
                    name: documentName,
                    description: documentDescription,
                    groupsCanAccess: []
                }}
        })

        return doc;
    } catch (e) {
        console.log("Failed to create " + documentName + " document", e);
        return null;
    }
}

async function uploadUserFile(fileName, fileDescription, file, fileStatus, documentID){
    let fileLocation = uuidv4()+'.pdf';

    try{
        let fileStorageResponse = await Storage.vault.put(fileLocation, file);

        try{
            let createFileResponse = await API.graphql({
                query: createFileMutation,
                variables: {input:{
                        documentID: documentID,
                        name: fileName,
                        description: fileDescription,
                        s3_location: fileLocation,
                        status: fileStatus,
                        groupsCanAccess: []
                    }}
            })

            return createFileResponse;
        } catch (e) {
            console.log("Failed to create " + fileName + " file", e);
            return null;
        }
    } catch(e) {
        console.log("Failed to upload " + fileName + " to S3", e);
        return null;
    }
}

async function fetchCompanyDetails() {
    const apiData = await API.graphql({ query: listCompanyDetailss });
    const companyDetails = apiData.data.listCompanyDetailss.items;

    if(companyDetails.length === 0){
        let company_details_response = await API.graphql({
            query: createCompanyDetails,
            variables: {input: {
                    groupsCanAccess: [""],
                    name: "My Company",
                    address: "1 Main St",
                }}
        }).catch(error => {
            console.log("Failed to create company details", error);
        });

        return company_details_response.data.createCompanyDetails;
    } else {
        return companyDetails[0];
    }
}