import React, { useState, useEffect }from "react";
import { API, Storage } from 'aws-amplify';

import Header from "../components/Headers/Header";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label,
    Media, Progress,
    Row,
    Table, UncontrolledDropdown,
    UncontrolledTooltip
} from "reactstrap";
import {getDocument, getShareholder} from "../graphql/queries";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import {displayAWSDate, displayAWSDateTime} from "../util/time";
import DocumentStatus from "../components/DocumentStatus";

class Shareholder extends React.Component {

    state = {
        Shareholder: {},
        downloadPath: ''
    };

    constructor(props) {
        super(props);

        this.fetchShareholder = this.fetchShareholder.bind(this);
        this.getShareholderDetail = this.getShareholderDetail.bind(this);

        this.fetchShareholder().catch(err => {
            console.log('Could not fetch shareholder', err);
        });
    }

    async fetchShareholder(){
        const apiData = await API.graphql({
            query: getShareholder,
            variables: {
                id: this.props.match.params.id
            }
        });

        const ShareholderFromAPI = apiData.data.getShareholder;

        console.log("shareholder", ShareholderFromAPI);

        this.setState({apiData: apiData.data, Shareholder: ShareholderFromAPI})
    }

    async onFileClick(file){
        let pdf_file = await Storage.vault.get(file.s3_location, {download: true});

        let arrayPromise = new Promise(function(resolve) {
            let reader = new FileReader();

            reader.onloadend = function() {
                resolve(reader.result);
            };

            reader.readAsArrayBuffer(pdf_file.Body);
        });

        let a = await arrayPromise;

        Storage.vault.get(file.s3_location).then(r => {
            this.setState({downloadPath: r})
        })

        this.setState({file: a, fileLocation: file.s3_location, selectedFile: file});
    }

    getShareholderDetail(detail){
        if(!this.state.Shareholder){
            return "";
        } else {
            return detail;
        }
    }

    onShareholderAgreementClick(id){

    }

    onShareClick(id){

    }

    render() {
        let shareholder = this.state.Shareholder;
        return (
            <>
                <Header />
                {/* Page content */}
                <Container className=" mt--7" fluid>
                    <Row>
                        <div className=" col">
                            <Card className=" shadow">
                                <CardHeader className=" bg-transparent">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Shareholder</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <h6 className="heading-small text-muted mb-4">
                                        Shareholder Details
                                    </h6>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-name"
                                                >
                                                    Full Name
                                                </label>
                                                <Input
                                                    disabled
                                                    id="input-name"
                                                    placeholder="Jane Doe"
                                                    type="text"
                                                    value={this.getShareholderDetail(shareholder.name)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <Label
                                                    className="form-control-label"
                                                    htmlFor="input-purchase-date"
                                                >
                                                    Start Date
                                                </Label>
                                                <Input
                                                    disabled
                                                    className="form-control-alternative"
                                                    defaultValue="2018-11-23"
                                                    id="input-purchase-date"
                                                    type="date"
                                                    value={this.getShareholderDetail(shareholder.start_date)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-shareholderaddress"
                                                >
                                                    Address
                                                </label>
                                                <Input
                                                    disabled
                                                    value={this.getShareholderDetail(shareholder.address)}
                                                    className="form-control-alternative"
                                                    id="input-shareholderaddress"
                                                    placeholder="Shareholder Address"
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <Label
                                                className="form-control-label"
                                                htmlFor="input-purchase-date"
                                            >
                                                Shareholder's Agreement
                                            </Label>
                                            <Table className="align-items-center table-flush" responsive hover>
                                                <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">Document Name</th>
                                                    <th scope="col">Updated Date</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    shareholder.shareholder_agreement && <tr key={shareholder.shareholder_agreement.id}
                                                                                             onClick={() => this.onShareholderAgreementClick(shareholder.shareholder_agreement)}>
                                                        <td>{shareholder.shareholder_agreement.name}</td>
                                                        <td>{displayAWSDateTime(shareholder.shareholder_agreement.updatedAt)}</td>
                                                        <td><DocumentStatus documentID={shareholder.shareholder_agreement_id}/></td>
                                                    </tr>
                                                }
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row>

                                    </Row>
                                    <h6 className="heading-small text-muted mb-4">
                                        Shares
                                    </h6>
                                    <Row>
                                        <Col>
                                            <Table className="align-items-center table-flush" responsive hover>
                                                <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">Cert. Number</th>
                                                    <th scope="col"># of Shares</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Paid/Share</th>
                                                    <th scope="col">Unpaid/Share</th>
                                                    <th scope="col">Purchase Date</th>
                                                    <th scope="col">Share Cert. Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    shareholder && shareholder.shares && shareholder.shares.items.map(Share => (
                                                        <tr key={Share.id} onClick={() => this.onShareClick(File)}>
                                                            <td>{Share.certificate_number}</td>
                                                            <td>{Share.number_of_shares}</td>
                                                            <td>{Share.share_type}</td>
                                                            <td>{Share.paid_per_share}</td>
                                                            <td>{Share.unpaid_per_share}</td>
                                                            <td>{Share.purchase_date}</td>
                                                            <td><DocumentStatus documentID={Share.share_certificate_id}/></td>
                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Shareholder;