/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNote = /* GraphQL */ `
  query GetNote($id: ID!) {
    getNote(id: $id) {
      groupsCanAccess
      owner
      id
      name
      description
      image
      createdAt
      updatedAt
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        groupsCanAccess
        owner
        id
        name
        description
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        groupsCanAccess
        owner
        id
        parentID
        name
        description
        document_type
        createdAt
        updatedAt
        files {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      groupsCanAccess
      owner
      id
      parentID
      name
      description
      document_type
      createdAt
      updatedAt
      files {
        items {
          groupsCanAccess
          owner
          id
          documentID
          name
          description
          s3_location
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      groupsCanAccess
      owner
      id
      documentID
      name
      description
      s3_location
      status
      createdAt
      updatedAt
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        groupsCanAccess
        owner
        id
        documentID
        name
        description
        s3_location
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPersonnel = /* GraphQL */ `
  query GetPersonnel($id: ID!) {
    getPersonnel(id: $id) {
      groupsCanAccess
      owner
      id
      name
      former_name
      address
      date_of_birth
      country_of_birth
      start_date
      end_date
      createdAt
      updatedAt
      documents {
        items {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        nextToken
      }
      roles {
        items {
          groupsCanAccess
          owner
          id
          title
          start_date
          end_date
          personnelID
          consent_document_id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPersonnels = /* GraphQL */ `
  query ListPersonnels(
    $filter: ModelPersonnelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonnels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        groupsCanAccess
        owner
        id
        name
        former_name
        address
        date_of_birth
        country_of_birth
        start_date
        end_date
        createdAt
        updatedAt
        documents {
          nextToken
        }
        roles {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPersonnelRole = /* GraphQL */ `
  query GetPersonnelRole($id: ID!) {
    getPersonnelRole(id: $id) {
      groupsCanAccess
      owner
      id
      title
      start_date
      end_date
      personnelID
      consent_document_id
      createdAt
      updatedAt
      consent_document {
        items {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPersonnelRoles = /* GraphQL */ `
  query ListPersonnelRoles(
    $filter: ModelPersonnelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonnelRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        groupsCanAccess
        owner
        id
        title
        start_date
        end_date
        personnelID
        consent_document_id
        createdAt
        updatedAt
        consent_document {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listShareholders = /* GraphQL */ `
  query ListShareholders(
    $filter: ModelShareholderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShareholders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        groupsCanAccess
        owner
        id
        name
        address
        start_date
        end_date
        shareholder_agreement_id
        createdAt
        updatedAt
        shareholder_agreement {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        shares {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getShareholder = /* GraphQL */ `
  query GetShareholder($id: ID!) {
    getShareholder(id: $id) {
      groupsCanAccess
      owner
      id
      name
      address
      start_date
      end_date
      shareholder_agreement_id
      createdAt
      updatedAt
      shareholder_agreement {
        groupsCanAccess
        owner
        id
        parentID
        name
        description
        document_type
        createdAt
        updatedAt
        files {
          nextToken
        }
      }
      documents {
        items {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        nextToken
      }
      shares {
        items {
          groupsCanAccess
          owner
          id
          certificate_number
          owner_id
          number_of_shares
          purchase_date
          share_type
          paid_per_share
          unpaid_per_share
          share_certificate_id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const getShares = /* GraphQL */ `
  query GetShares($id: ID!) {
    getShares(id: $id) {
      groupsCanAccess
      owner
      id
      certificate_number
      owner_id
      number_of_shares
      purchase_date
      share_type
      paid_per_share
      unpaid_per_share
      share_certificate_id
      createdAt
      updatedAt
      documents {
        items {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        nextToken
      }
      share_certificate {
        groupsCanAccess
        owner
        id
        parentID
        name
        description
        document_type
        createdAt
        updatedAt
        files {
          nextToken
        }
      }
      shareholder {
        groupsCanAccess
        owner
        id
        name
        address
        start_date
        end_date
        shareholder_agreement_id
        createdAt
        updatedAt
        shareholder_agreement {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        shares {
          nextToken
        }
      }
    }
  }
`;
export const listSharess = /* GraphQL */ `
  query ListSharess(
    $filter: ModelSharesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSharess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        groupsCanAccess
        owner
        id
        certificate_number
        owner_id
        number_of_shares
        purchase_date
        share_type
        paid_per_share
        unpaid_per_share
        share_certificate_id
        createdAt
        updatedAt
        documents {
          nextToken
        }
        share_certificate {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        shareholder {
          groupsCanAccess
          owner
          id
          name
          address
          start_date
          end_date
          shareholder_agreement_id
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getContractor = /* GraphQL */ `
  query GetContractor($id: ID!) {
    getContractor(id: $id) {
      groupsCanAccess
      owner
      id
      name
      Address
      NDA_status
      IP_status
      Workers_Comp_status
      createdAt
      updatedAt
    }
  }
`;
export const listContractors = /* GraphQL */ `
  query ListContractors(
    $filter: ModelContractorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        groupsCanAccess
        owner
        id
        name
        Address
        NDA_status
        IP_status
        Workers_Comp_status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      groupsCanAccess
      owner
      id
      name
      address
      createdAt
      updatedAt
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        groupsCanAccess
        owner
        id
        name
        address
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyDetails = /* GraphQL */ `
  query GetCompanyDetails($id: ID!) {
    getCompanyDetails(id: $id) {
      groupsCanAccess
      owner
      id
      name
      address
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyDetailss = /* GraphQL */ `
  query ListCompanyDetailss(
    $filter: ModelCompanyDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyDetailss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        groupsCanAccess
        owner
        id
        name
        address
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
