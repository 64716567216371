export function displayAWSDate(AWSdate){
    let date = new Date(AWSdate);

    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();

    return  mm + '/' + dd + '/' + yyyy;
}

export function displayAWSDateTime(AWSDateTime){
    let date = new Date(AWSDateTime);

    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();

    return  mm + '/' + dd + '/' + yyyy + ' ' + date.getHours() + ':' + date.getMinutes();
}