import React from 'react';
import { Page, Document, Image, StyleSheet } from '@react-pdf/renderer';
import CapTableTitle from "./Components/CapTableTitle";
import CompanyInfo from "./Components/CompanyInfo";
import CapTableItemsTable from "./Components/CapTableItemsTable";

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
  });
  
  const CapTable = ({shareholders}) => (
            <Document>
                <Page size="A4" style={styles.page} orientation="landscape">
                    <CapTableTitle title='Cap Table'/>
                    <CompanyInfo shareholders={shareholders}/>
                    <CapTableItemsTable shareholders={shareholders} />
                </Page>
            </Document>
        );
  
  export default CapTable