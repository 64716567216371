export default {
    items: [
        {
            id: 'pages',
            title: 'Pages',
            type: 'group',
            icon: 'icon-pages',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/',
                    classes: 'nav-item',
                    icon: 'feather icon-sidebar'
                },
                {
                    id: 'weather',
                    title: 'Weather',
                    type: 'item',
                    url: '/weather',
                    icon: 'feather icon-cloud-lightning',
                },
                {
                    id: 'my-devices',
                    title: 'My Devices',
                    type: 'item',
                    url: '/devices',
                    icon: 'feather icon-speaker',
                },
                {
                    id: 'signout-1',
                    title: 'Sign out',
                    type: 'item',
                    url: '/login',
                    icon: 'feather icon-lock',
                    breadcrumbs: false
                },
            ]
        }
    ]
}