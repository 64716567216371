import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import {Row, Col} from "react-bootstrap";
import {FormGroup, Input} from "reactstrap";
import FileUpload from "../../../components/CustomUpload/FileUpload";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch
};

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      desgin: false,
      code: false,
      develop: false,
      constitution_status: "Missing",
      constitution: null,
      founders_agreement_status: "Missing",
      founders_agreement: null,
    };
  }
  sendState() {
    return this.state;
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  isValidated() {
    return true;
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>
              Add your Company Constitution
            </h4>
          </GridItem>
        </GridContainer>
        <Row>
          <Col>
            <Input
                className="form-control-alternative"
                onChange={e => this.setState({constitution_status: e.target.value})}
                id="input-shareholder-status"
                type="select"
            >
              <option>Missing</option>
              <option>Need Signature</option>
              <option>Complete</option>
            </Input>
          </Col>
          <Col>
            {this.state.constitution_status !== "Missing" && <FileUpload
                onFileChange={file => {
                  this.setState({constitution: file})
                }}/>}
          </Col>

        </Row>
        <hr />
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>
              Add your Founder's Agreement
            </h4>
          </GridItem>
        </GridContainer>
        <Row>
          <Col>
            <Input
                className="form-control-alternative"
                onChange={e => this.setState({founders_agreement_status: e.target.value})}
                id="input-shareholder-status"
                type="select"
            >
              <option>Missing</option>
              <option>Need Signature</option>
              <option>Complete</option>
            </Input>
          </Col>
          <Col>
            {this.state.founders_agreement_status !== "Missing" && <FileUpload
                onFileChange={file => {
                  this.setState({founders_agreement: file})
                }}/>}
          </Col>
        </Row>
      </div>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Step2);
