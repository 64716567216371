import {Auth} from "aws-amplify";
import DirectorRegister from "../templates/DirectorRegister/DirectorRegister";
import {pdf} from "@react-pdf/renderer";
import React from "react";
import MemberRegister from "../templates/MemberRegister/MemberRegister";
import ShareRegister from "../templates/ShareRegister/ShareRegister";

export function downloadMemberRegister(companyName, companyAddress, Personnel){
    let members = {};

    members.company = companyName || "My Company";
    members.address = companyAddress || "Unknown Address";

    members.items = [];

    for(let person of Personnel){
        let member = {
            id: person.id,
            name: person.name,
            former_name: person.former_name,
            address: person.address,
            start_date: person.start_date,
            end_date: person.end_date,
            roles: ""
        };

        if(person.roles.items && person.roles.items.length > 0){
            for(let role of person.roles.items){
                if(member.roles === ""){
                    member.roles = role.title;
                } else {
                    member.roles += ", " + role.title;
                }
            }
        }

        members.items.push(member);
    }

    const doc = (<MemberRegister members={members}/>);

    pdf(doc).toBlob().then(r => {
        const fileURL = URL.createObjectURL(r);

        window.open(fileURL, "_blank");
    })
}

export function downloadShareRegister(companyName, companyAddress, shares){
    let share_data = {};

    share_data.company = companyName || "My Company";
    share_data.address = companyAddress || "Unknown Address";

    share_data.items = [];

    for(let share of shares){
        let share_item = {
            id: share.id,
            shareholder_name: share.shareholder.name,
            number_of_shares: share.number_of_shares,
            share_type: share.share_type,
            purchase_date: share.purchase_date,
        };

        share_data.items.push(share_item);
    }

    const doc = (<ShareRegister shares={share_data}/>);

    pdf(doc).toBlob().then(r => {
        const fileURL = URL.createObjectURL(r);

        window.open(fileURL, "_blank");
    })
}