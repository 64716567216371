import React from "react";

// core components
import Wizard from "components/Wizard/Wizard.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Header from "../../components/Headers/Header";
import Step1 from "./WizardSteps/Step1";
import Step2 from "./WizardSteps/Step2";
import {API} from "aws-amplify";
import {updateCompanyDetails} from "../../graphql/mutations";
import {awsInteraction} from "../../util/graphQL";
import Step3 from "./WizardSteps/Step3";
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import { Link } from 'react-router-dom';


export default function WizardView(props) {
    let processingForm = false;
    let complete = false;

    async function uploadCompanyDetails(e){
        processingForm = true;

        let documentResponse = await awsInteraction.createDocument("Company Constitution", "");

        if(!documentResponse){
            console.log("Failed to create constitution document");
            processingForm = false;
            return;
        }

        let fileResponse = await awsInteraction.createFile("Company Constitution",
            "Company Constitution: " + e.documents.constitution_status, e.documents.constitution,
            e.documents.constitution_status, documentResponse.data.createDocument.id);

        let founderResponse = await awsInteraction.createDocument("Founder's Agreement", "");

        if(!founderResponse){
            console.log("Failed to create founders agreement document");
            processingForm = false;
            return;
        }

        let founderFileResponse = await awsInteraction.createFile("Founder's Agreement",
            "Founder's Agreement: " + e.documents.founders_agreement_status, e.documents.founders_agreement,
            e.documents.founders_agreement_status, founderResponse.data.createDocument.id);

        let companyDetails = await awsInteraction.fetchCompanyDetails();

        let company_detail_response = await API.graphql({
            query: updateCompanyDetails,
            variables: {
                input: {
                    id: companyDetails.id,
                    name: e.details.name,
                    address: e.details.address,
                    completedSetupWizard: true
                },
            }
        })

        this.processingForm = false;
        complete = true;
    }

    return (
        <>
            <Header />
            <GridContainer justify="center">
                {!complete && <GridItem xs={12} sm={8}>
                    <Wizard
                        validate
                        steps={[
                            {stepName: "Company Details", stepComponent: Step1, stepId: "details"},
                            {stepName: "Key Documents", stepComponent: Step2, stepId: "documents"},
                        ]}
                        title="Company Setup"
                        subtitle="This information will let us know more about you!"
                        finishButtonClick={e => uploadCompanyDetails(e)}
                    />
                </GridItem>
                }
                {complete && <GridItem xs={12} sm={8}>
                    <Card>
                        <CardHeader>
                            Complete!
                        </CardHeader>
                        <CardBody>
                            <p>Next step is to add your public officers and shareholders</p>

                            <Button>
                                <Link to={'/company'}>
                                    Go to company page
                                </Link>
                            </Button>
                        </CardBody>
                    </Card>
                </GridItem>}
            </GridContainer>
        </>
    );
}
