import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {Auth} from "aws-amplify";

export class AuthPrivateRoute extends React.Component {
    state = {
        loading: true,
        isAuthenticated: false,
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser().then((isAuthenticated) => {
            console.log(isAuthenticated);
            this.setState({
                loading: false,
                isAuthenticated,
            });
        }).catch((error) => {
            console.log('User is not currently authenticated', error);
            this.setState({
                loading: false,
                isAuthenticated: false,
            });
        });
    }

    render() {
        const { component: Component, ...rest } = this.props;
        if (this.state.loading) {
            return <div>LOADING</div>;
        } else {
            return (
                <Route {...rest} render={props => (
                    <div>
                        {!this.state.isAuthenticated && <Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />}
                        <Component {...this.props} />
                    </div>
                )}
                />
            )
        }
    }
}