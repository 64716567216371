import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import ShareTableHeader from "./ShareTableHeader";
import ShareTableRow from "./ShareTableRow";

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
});

  const ShareItemsTable = ({shares}) => (
    <View style={styles.tableContainer}>
        <ShareTableHeader />
        <ShareTableRow items={shares.items} />
    </View>
  );
  
  export default ShareItemsTable