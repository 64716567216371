import React from 'react';

import Login from "./views/auth/Login";
import {RequestResetPassword} from "./components/Auth/RequestResetPassword";
import {ResetPassword} from "./components/Auth/ResetPassword";
import Register from "./components/Auth/Register";
import NewDocument from "./views/new_forms/NewDocument";
import Document from "./views/document";
import NewFileVersion from "./views/new_forms/NewFile";
import NewShares from "./views/new_forms/NewShares";
import NewMember from "./views/new_forms/NewMember";
import settings from "./views/settings";
import Shareholder from "./views/shareholder";
import Officer from "./views/officer";
import WizardView from "./views/first_time_wizard/Wizard";

const other_routes = [
  { path: '/new-document', exact: false, name: 'New Document', component: NewDocument},
  { path: '/new-shares', exact: false, name: 'New Shares', component: NewShares},
  { path: '/new-member', exact: false, name: 'New Shares', component: NewMember},
  { path: '/document/:id/new', exact: false, name: 'New File', component: NewFileVersion},
  { path: '/document/:id', exact: false, name: 'Document', component: Document},
  { path: '/shareholder/:id', exact: false, name: 'Shareholder', component: Shareholder},
  { path: '/officer/:id', exact: false, name: 'Officer', component: Officer},
  { path: '/settings', exact: true, name: 'Settings', component: settings},
  { path: '/first_time', exact: true, name: 'First Time Setup', component: WizardView},
  { path: '/test_wizard', exact: true, name: 'Wizard', component: WizardView},
];

export default other_routes;