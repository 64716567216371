/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  CardFooter,
  Container,
  Row,
  Col,
  CardImg,
  Media,
  UncontrolledTooltip,
  Badge,
  CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, FormGroup
} from "reactstrap";

import { Link } from 'react-router-dom';

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import {API, Auth, Storage} from "aws-amplify";
import {listDocumentsAndFiles} from "../graphql/custom_queries";
import {awsInteraction} from "../util/graphQL";
import GridItem from "../components/Grid/GridItem";
import GridContainer from "../components/Grid/GridContainer";

class Index extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      activeNav: 1,
      link: "/new-member",
      todos: []
    };

    this.getRequiredActions().catch(err => {
      console.log('Could not fetch Documents', err);
    });

    awsInteraction.fetchCompanyDetails().then(companyDetails => {
      this.setState({
        companyName: companyDetails.name,
        completedSetupWizard: companyDetails.completedSetupWizard
      });
    })
  }

  async getRequiredActions() {
    const apiData = await API.graphql({ query: listDocumentsAndFiles });
    const DocumentsFromAPI = apiData.data.listDocuments.items;

    await Promise.all(DocumentsFromAPI.map(async Document => {
      return Document;
    }))

    this.setState({Documents: DocumentsFromAPI})

    let todos = []

    for(let document of DocumentsFromAPI){
      let todo;

      if(document.files.items.length === 0){
        todo = {id: document.id, document_name: document.name, status: 'Missing'}
      } else if (document.files.items[document.files.items.length-1].status !== 'Complete'){
        todo = {id: document.id, document_name: document.name, status: document.files.items[document.files.items.length-1].status}
      }

      if(todo){
        todos.push(todo);
      }
    }

    this.setState({todos: todos});
  }

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index
    });
  };

  componentDidMount() {
    Auth.currentUserInfo().then(r => {
      if(r){
        this.setState({companyName: r.attributes['custom:CompanyName'] || "My Company"});
      }
    })
  }

  onActionChange(value){
    this.setState({link: value});
  }

  skipFirstTimeSetup(){

  }

  render() {
    return (
        <>
          <Header />
          {/* Page content */}
          <Container className=" mt--7" fluid>
            <h1 className="title ml-auto mr-auto text-center">{this.state.companyName}</h1>
            {!this.state.completedSetupWizard && this.state.completedSetupWizard === false && <GridContainer justify="center">
              <GridItem xs={6} sm={6}>
                <Card>
                  <CardHeader>
                    Welcome to Securetary! Would you like to go through first time setup?
                  </CardHeader>
                  <CardBody>
                    <Button color="primary"
                    >
                      <Link className="button-link" to={'/first_time'}>Yes</Link>
                    </Button>
                    <Button onClick={e => (this.skipFirstTimeSetup())}>
                      Never
                    </Button>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            }
            <Row>
              <Col md="4">
                <Link to={'/new-member'}>
                  <Card className="card-pricing" data-color="orange">
                    <CardBody>
                      <CardTitle tag="h3">New Public Officer</CardTitle>

                      <CardFooter>
                        <p className="card-description">
                          Add a new secretary/director/chairman
                        </p>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col md="4">
                <Link to={'/new-shares'}>
                  <Card className="card-pricing" data-color="green">
                    <CardBody>
                      <CardTitle tag="h3">Issue Shares</CardTitle>
                      <CardFooter>
                        <p className="card-description">
                          Issue shares to an existing or new shareholder
                        </p>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
              <Col md="4">
                <Link to={'/new-document'}>
                  <Card className="card-pricing" data-color="blue">
                    <CardBody>
                      <CardTitle tag="h3">New General Document</CardTitle>
                      <CardFooter>
                        <p className="card-description">
                          Create and track versions of a document
                        </p>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardHeader>To Do's</CardHeader>
                  <CardBody>
                    <Table className="align-items-center table-flush" responsive hover>
                      <thead className="thead-light">
                      <tr>
                        <th className="sort" data-sort="name" scope="col">
                          Document Title
                        </th>
                        <th className="sort" data-sort="budget" scope="col">
                          Task
                        </th>
                        <th scope="col" />
                      </tr>
                      </thead>
                      <tbody className="list">
                      {
                        this.state.todos && this.state.todos.map(todo => (
                            <tr>
                              <th scope="row">
                            <span className="name mb-0 text-sm">
                              {todo.document_name}
                            </span>
                              </th>
                              <td className="budget">{todo.status}</td>
                              <td className="text-right">
                                <Button color="primary"
                                        size="sm">
                                  <Link className="button-link" to={'/document/'+todo.id}>Fix</Link>
                                </Button>
                              </td>
                            </tr>
                        ))
                      }
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
    );
  }
}

export default Index;
