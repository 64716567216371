import React, { useState, useEffect }from "react";
import {API, Auth, Storage} from 'aws-amplify';

import {listDocumentsAndFiles, listPersonnelsAndRoles} from "../graphql/custom_queries";
import Header from "../components/Headers/Header";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input,
    Media, Nav, Progress,
    Row,
    Table, UncontrolledDropdown,
    UncontrolledTooltip,
    Modal,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";
import {Link} from "react-router-dom";
import {listCompanyDetailss, listPersonnels, listSharess} from "../graphql/queries";
import {displayAWSDate} from "../util/time";
import DirectorRegister from "../templates/DirectorRegister/DirectorRegister";
import invoice from "../templates/CapTable/data/invoice";
import {pdf} from "@react-pdf/renderer";
import CapTable from "../templates/CapTable/CapTable";
import {
    createCompanyDetails,
    createDocument as createDocumentMutation,
    updateCompanyDetails
} from "../graphql/mutations";
// nodejs library that concatenates classes
import classnames from "classnames";
import {awsInteraction} from "../util/graphQL";
import {downloadMemberRegister, downloadShareRegister} from "../util/downloadRegister";

class company extends React.Component {

    state = {
        Documents: [],
        Personnel: [],
        Shares: [],
        DocumentName: '',
        DocumentDescription: '',
        formModal: false,
        tempCompanyName: '',
        tempCompanyAddress: ''
    };

    constructor(props) {
        super(props);

        this.fetchMembers = this.fetchMembers.bind(this);
        this.fetchShares = this.fetchShares.bind(this);
    }

    componentDidMount() {
        this.fetchMembers().then(personnel => {
            this.setState({Personnel: personnel});
        });

        this.fetchShares().then(shares => {
            this.setState({Shares: shares});
        });

        awsInteraction.fetchCompanyDetails().then(companyDetails => {
            this.setState({
                companyDetails: companyDetails,
                companyName: companyDetails.name,
                companyAddress: companyDetails.address
            });
        })
    }

    async fetchMembers() {
        const apiData = await API.graphql({ query: listPersonnelsAndRoles });

        return apiData.data.listPersonnels.items;
    }

    async fetchShares() {
        const apiData = await API.graphql({ query: listSharess });
        return apiData.data.listSharess.items;
    }

    onShareClick(Shares){
        this.props.history.push('/shareholder/'+Shares.owner_id);
    }

    onMemberClick(Member){
        this.props.history.push('/officer/'+Member.id);
    }

    downloadShareRegister(){
        Auth.currentUserInfo().then(r => {
            if(!r) return;

            let shareholders = {};

            shareholders.company = this.state.companyName || "My Company";
            shareholders.address = this.state.companyAddress || "Unknown Address";

            shareholders.items = [];

            for(let shares of this.state.Shares){
                shareholders.items.push({
                    name: shares.name,
                    capital: shares.former_name,
                    common_shares: shares.common_shares,
                    pref_shares: shares.pref_shares,
                    total_shares: shares.total_shares,
                    percent_shares: shares.percent_shares
                });
            }

            const doc = (<CapTable shareholders={shareholders}/>);

            pdf(doc).toBlob().then(r => {
                const fileURL = URL.createObjectURL(r);

                window.open(fileURL, "_blank");
            })
        })
    }

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    async onCompanyDetailsChange(){
        let document_response = await API.graphql({
            query: updateCompanyDetails,
            variables: {
                input: {
                    id: this.state.companyDetails.id,
                    name: this.state.tempCompanyName,
                    address: this.state.tempCompanyAddress,
                },
            }
        }).then(r => {
            this.toggleModal("formModal");
            this.setState({
                companyName: this.state.tempCompanyName,
                companyAddress: this.state.tempCompanyAddress
            })
        }).catch(error => {
            console.log("Failed to update company details", error);
        });
    }

    render() {
        return (
            <>
                <Header />
                {/* Page content */}
                <Container className=" mt--7" fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Company Details</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <Button
                                                color="primary"
                                                size="sm"
                                                onClick={() => (this.setState({formModal: true}))}
                                            >
                                                Edit Details
                                            </Button>
                                            <Modal
                                                className="modal-dialog-centered"
                                                size="sm"
                                                isOpen={this.state.formModal}
                                                toggle={() => this.toggleModal("formModal")}
                                            >
                                                <div className="modal-body p-0">
                                                    <Card className="bg-secondary border-0 mb-0">
                                                        <CardHeader className="bg-transparent pb-5">
                                                            <div className="text-muted text-center mt-2 mb-3">
                                                                <h3>Company Details</h3>
                                                            </div>
                                                        </CardHeader>
                                                        <CardBody className="px-lg-5 py-lg-5">
                                                            <Form role="form">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-name"
                                                                    >
                                                                        Company Name
                                                                    </label>
                                                                    <Input
                                                                        onChange={e => this.setState({tempCompanyName: e.target.value})}
                                                                        value={this.state.tempCompanyName}
                                                                        id="input-name"
                                                                        placeholder="My Company"
                                                                        type="text"
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-company-name"
                                                                    >
                                                                        Address
                                                                    </label>
                                                                    <Input
                                                                        onChange={e => this.setState({tempCompanyAddress: e.target.value})}
                                                                        id="input-company-name"
                                                                        type="text"
                                                                        placeholder="Address"
                                                                        value={this.state.tempCompanyAddress}
                                                                    />
                                                                </FormGroup>
                                                                <div className="text-center">
                                                                    <Button
                                                                        className="my-4"
                                                                        color="primary"
                                                                        type="button"
                                                                        onClick={() => this.toggleModal("formModal")}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                    <Button
                                                                        className="my-4"
                                                                        color="primary"
                                                                        type="button"
                                                                        onClick={() => this.onCompanyDetailsChange()}
                                                                    >
                                                                        Update
                                                                    </Button>
                                                                </div>
                                                            </Form>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </Modal>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-name"
                                                    >
                                                        Company Name
                                                    </label>
                                                    <Input
                                                        value={this.state.companyName}
                                                        id="input-name"
                                                        placeholder="My Company"
                                                        disabled
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-company-name"
                                                    >
                                                        Address
                                                    </label>
                                                    <Input
                                                        id="input-company-name"
                                                        type="text"
                                                        placeholder="Address"
                                                        disabled
                                                        value={this.state.companyAddress}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <div className=" col">
                            <Card className=" shadow">
                                <CardHeader className=" bg-transparent">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Directors and Secretaries</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <Button
                                                color="primary"
                                                size="sm"
                                            >
                                                <Link
                                                    className="button-link"
                                                    to="/new-member"
                                                >New Member</Link>
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Table className="align-items-center table-flush" responsive hover>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Role(s)</th>
                                                <th scope="col">Address</th>
                                                <th scope="col">Start Date</th>
                                                <th scope="col">Date Left</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.Personnel.map(Personnel => (
                                                <tr key={Personnel.id}>
                                                    <td onClick={() => this.onMemberClick(Personnel)}>{Personnel.name}</td>
                                                    <td onClick={() => this.onMemberClick(Personnel)}>
                                                        {Personnel.roles.items.map(role => (
                                                            <div key={role.id}>{role.title} </div>
                                                        ))}
                                                    </td>
                                                    <td onClick={() => this.onMemberClick(Personnel)}>{Personnel.address}</td>
                                                    <td onClick={() => displayAWSDate(this.onMemberClick(Personnel))}>{Personnel.start_date}</td>
                                                    <td onClick={() => displayAWSDate(this.onMemberClick(Personnel))}>{Personnel.end_date}</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </Table>
                                    <Col className="text-right">
                                        <Button
                                            color="info"
                                            onClick={e => (
                                                downloadMemberRegister(this.state.companyName, this.state.companyAddress,
                                                    this.state.Personnel)
                                            )}
                                            size="sm"
                                        >
                                            Download Member Register
                                        </Button>
                                    </Col>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                    <Row>
                        <div className=" col">
                            <Card className=" shadow">
                                <CardHeader className=" bg-transparent">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Share Register</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <Button
                                                color="primary"
                                                size="sm"
                                            >
                                                <Link
                                                    className="button-link"
                                                    to="/new-shares"
                                                >Issue New Shares</Link>
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Table className="align-items-center table-flush" responsive hover>
                                        <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Share Certificate Number</th>
                                            <th scope="col">Owner Name</th>
                                            <th scope="col">Share Type</th>
                                            <th scope="col">Shares Owned</th>
                                            <th scope="col">Purchase Date</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.Shares.map(Shares => (
                                                <tr key={Shares.id}>
                                                    <td onClick={() => this.onShareClick(Shares)}>{Shares.certificate_number}</td>
                                                    <td onClick={() => this.onShareClick(Shares)}>{Shares.shareholder.name}</td>
                                                    <td onClick={() => this.onShareClick(Shares)}>{Shares.share_type}</td>
                                                    <td onClick={() => this.onShareClick(Shares)}>{Shares.number_of_shares}</td>
                                                    <td onClick={() => displayAWSDate(this.onShareClick(Shares))}>{Shares.purchase_date}</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </Table>
                                    <Col className="text-right">
                                        <Button
                                            color="info"
                                            onClick={e => (
                                                downloadShareRegister(this.state.companyName, this.state.companyAddress,
                                                    this.state.Shares)
                                            )}
                                            size="sm"
                                        >
                                            Download Share Register
                                        </Button>
                                    </Col>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

/*

<Row>
                        <div className=" col">
                            <Card className=" shadow">
                                <CardHeader className=" bg-transparent">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Employees</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <Button
                                                color="primary"
                                                href="/new-shares"
                                                size="sm"
                                            >
                                                New Employee
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Table className="align-items-center table-flush" responsive hover>
                                        <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">Employee Status</th>
                                            <th scope="col">IP Transfer Status</th>
                                            <th scope="col">ESOP Status</th>
                                            <th scope="col">NDA Status</th>
                                            <th scope="col">Superannuation Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>

 */

export default company;