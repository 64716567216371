import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import CapTableTableHeader from "./CapTableTableHeader";
import CapTableTableRow from "./CapTableTableRow";
import CapTableTableFooter from "./CapTableTableFooter";

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
});

  const CapTableItemsTable = ({shareholders}) => (
    <View style={styles.tableContainer}>
        <CapTableTableHeader />
        <CapTableTableRow items={shareholders.items} />
        <CapTableTableFooter items={shareholders.items} />
    </View>
  );
  
  export default CapTableItemsTable