import React, { useState, useEffect }from "react";
import { API, Storage } from 'aws-amplify';

import {listDocumentsAndFiles} from "../graphql/custom_queries";
import Header from "../components/Headers/Header";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, DropdownItem, DropdownMenu, DropdownToggle,
    Media, Nav, Progress,
    Row,
    Table, UncontrolledDropdown,
    UncontrolledTooltip
} from "reactstrap";
import {Link} from "react-router-dom";
import {listPersonnels, listSharess} from "../graphql/queries";

class Customers extends React.Component {

    state = {
        Documents: [],
        Personnel: [],
        Shares: [],
        DocumentName: '',
        DocumentDescription: '',
    };

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Header />
                {/* Page content */}
                <Container className=" mt--7" fluid>
                    <Row>
                        <div className=" col">
                            <Card className=" shadow">
                                <CardHeader className=" bg-transparent">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Customers</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <Button
                                                color="primary"
                                                href="/new-shares"
                                                size="sm"
                                            >
                                                New Customer
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Table className="align-items-center table-flush" responsive hover>
                                        <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">NDA Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Customers;