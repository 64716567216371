import React from "react";
import {API, Auth} from 'aws-amplify';

import {listCompanyDetailss, listShareholders} from '../../graphql/queries';
import Header from "../../components/Headers/Header";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row, Label} from "reactstrap";

import {
    createShareholder, createShares
} from '../../graphql/mutations';

import { makeStyles } from "@material-ui/core/styles";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import SubmitButton from "../../components/CustomButtons/SubmitButton";
import FileUpload from "../../components/CustomUpload/FileUpload";
import {awsInteraction} from "../../util/graphQL";

const styles = {
    cardTitle,
    pageSubcategoriesTitle: {
        color: "#3C4858",
        textDecoration: "none",
        textAlign: "center"
    },
    cardCategory: {
        margin: "0",
        color: "#999999"
    }
};

class NewShares extends React.Component {

    state = {
        owner_id: 'New Shareholder',
        shareholderName: "",
        shareholderAddress: "",
        number_of_shares: 0,
        certificate_number: 1,
        purchaseDate: '2018-11-18',
        share_type: 'Ordinary',
        paid_per_share: 0,
        unpaid_per_share: 0,
        share_certificate_status: 'Missing',
        share_certificate_document: null,
        shareholders_agreement_status: 'Missing',
        shareholders_agreement_document: null,
        shareholders: [],
        processingForm: false
    };

    constructor(props) {
        super(props);

        this.createShareDocument = this.createShareDocument.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onOwnerSelect = this.onOwnerSelect.bind(this);

        this.getCompanyDetails();
        this.getShareholders();

        Auth.currentAuthenticatedUser().then(r => (
            this.setState({username: r.username})
        ));
    }

    componentDidMount() {
        this.setState({classes: makeStyles(styles)});
    }

    checkValidSubmission() {
        if(!this.state.username){
            return 'username';
        }

        if (!this.state.owner_id) {
            return 'owner id';
        }

        if (!this.state.number_of_shares){
            return 'number of shares';
        }
        if (!this.state.purchaseDate){
            return 'purchase date';
        }

        if(!this.state.share_type){
            return 'share type';
        }

        return null;
    }

    async createShareDocument() {
        let valid_res = this.checkValidSubmission();

        if(valid_res) {
            console.log(valid_res + ' is missing in the submission form');
            return;
        }

        this.setState({processingForm: true});

        let owner_id, shareholderName;

        //Create new person if necessary
        if(this.state.owner_id === "New Shareholder"){
            shareholderName = this.state.shareholderName;

            let documentName = "Shareholder's Agreement - " + shareholderName;
            let documentDescription = "Shareholder's Agreement for " + shareholderName;

            let fileName = "Shareholder's Agreement for " + shareholderName;
            let fileDescription = "Shareholder's Agreement - " + this.state.shareholders_agreement_status;

            let shareholdersAgreementDocumentResponse = await awsInteraction.createDocument(documentName, documentDescription)

            if(!shareholdersAgreementDocumentResponse){
                this.setState({processingForm: false});
                return false;
            }

            let shareholdersAgreementFileResponse = await awsInteraction.createFile(fileName, fileDescription,
                this.state.share_certificate_document, this.state.share_certificate_status,
                shareholdersAgreementDocumentResponse.data.createDocument.id);

            if(!shareholdersAgreementFileResponse){
                this.setState({processingForm: false});
                return false;
            }

            let new_shareholder_input = {
                name: this.state.shareholderName,
                address: this.state.shareholderAddress,
                start_date: this.state.purchaseDate,
                shareholder_agreement_id: shareholdersAgreementDocumentResponse.data.createDocument.id,
                groupsCanAccess: []
            };

            let new_shareholder = await API.graphql({
                query: createShareholder,
                variables: {input: new_shareholder_input},
            }).catch(err => {
                console.log('Failed to create shareholder', err);
                this.setState({processingForm: false});
            });

            owner_id = new_shareholder.data.createShareholder.id;
        } else {
            owner_id = this.state.owner_id;

            for(let shareholder of this.state.shareholders){
                if(shareholder.id === owner_id){
                    shareholderName = shareholder.name;
                }
            }
        }

        //Create document for share files
        let documentName = "Share Certificate " + this.state.certificate_number + " - " + shareholderName;
        let documentDescription = 'Share Certificate for ' + shareholderName;

        let fileName = "Share Certificate for " + shareholderName;
        let fileDescription = "Issued " + this.state.number_of_shares + " on " + this.state.purchase_date;

        let document_status = this.state.share_certificate_status;
        let document = this.state.share_certificate_document;

        let shareCertificateDocumentResponse = await awsInteraction.createDocument(documentName, documentDescription);

        if(!shareCertificateDocumentResponse){
            this.setState({processingForm: false});
            return;
        }

        let shareCertificateFileResponse = await awsInteraction.createFile(fileName, fileDescription, document,
            document_status, shareCertificateDocumentResponse.data.createDocument.id);

        if(!shareCertificateFileResponse){
            this.setState({processingForm: false});
            return;
        }

        //Create share register
        let share_response = await API.graphql({
            query: createShares,
            variables: {
                input: {
                    certificate_number: parseInt(this.state.certificate_number),
                    owner_id: owner_id,
                    number_of_shares: parseInt(this.state.number_of_shares),
                    purchase_date: this.state.purchaseDate,
                    share_type: this.state.share_type,
                    paid_per_share: parseInt(this.state.paid_per_share),
                    unpaid_per_share: parseInt(this.state.unpaid_per_share),
                    share_certificate_id: shareCertificateDocumentResponse.data.createDocument.id,
                    groupsCanAccess: []
                }
            }
        }).catch(error => {
            console.log("Failed creating shares", error);
            this.setState({processingForm: false});
            return;
        })

        this.setState({processingForm: false});
        this.props.history.push('/company');
    }

    getShareholders(){
        API.graphql({ query: listShareholders }).then(
            apiData => {
                const shareholdersFromAPI = apiData.data.listShareholders.items;
                this.setState({shareholders: shareholdersFromAPI})
            }
        );
    }

    getCompanyDetails(){
        API.graphql({ query: listCompanyDetailss }).then(
            apiData => {
                const companyDetailsFromAPI = apiData.data.listCompanyDetailss.items;
                console.log('Company Details', companyDetailsFromAPI);
                this.setState({companyDetails: companyDetailsFromAPI})
            }
        );
    }

    onOwnerSelect(event) {
        this.setState({owner_id: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();

        this.createShareDocument().catch(err => {
            console.log(err);
        });
    }

    render() {
        return (
            <>
                <Header />
                {/* Page content */}
                <Container className=" mt--7" fluid>
                    <Row>
                        <div className=" col">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <h3 className="mb-0">New Shares</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={this.handleSubmit}>
                                        <Row>
                                            <Col>
                                                <h6 className="heading-small text-muted mb-4">
                                                    Shareholder Details
                                                </h6>
                                                <div className="pl-lg-4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-owner"
                                                        >
                                                            Shareholder
                                                        </label>
                                                        <Input
                                                            onChange={this.onOwnerSelect}
                                                            className="form-control-alternative"
                                                            id="input-owner"
                                                            type="select"
                                                        >
                                                            <option value={null}>New Shareholder</option>
                                                            {
                                                                this.state.shareholders.map(shareholders => (
                                                                    <option value={shareholders.id}>{shareholders.name}</option>
                                                                ))
                                                            }
                                                        </Input>
                                                    </FormGroup>
                                                    {
                                                        this.state.owner_id === "New Shareholder" &&
                                                        <div>
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-shareholdername"
                                                                >
                                                                    Full Name
                                                                </label>
                                                                <Input
                                                                    onChange={e => this.setState({shareholderName: e.target.value})}
                                                                    value={this.state.shareholderName}
                                                                    className="form-control-alternative"
                                                                    id="input-shareholdername"
                                                                    placeholder="Shareholder Name"
                                                                    type="text"
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-shareholderaddress"
                                                                >
                                                                    Address
                                                                </label>
                                                                <Input
                                                                    onChange={e => this.setState({shareholderAddress: e.target.value})}
                                                                    value={this.state.shareholderAddress}
                                                                    className="form-control-alternative"
                                                                    id="input-shareholderaddress"
                                                                    placeholder="Shareholder Address"
                                                                    type="text"
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-shareholder-status"
                                                                >
                                                                    Shareholders Agreement
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    onChange={e => this.setState({shareholders_agreement_status: e.target.value})}
                                                                    id="input-shareholder-status"
                                                                    type="select"
                                                                >
                                                                    <option>Missing</option>
                                                                    <option>Need Signature</option>
                                                                    <option>Complete</option>
                                                                </Input>
                                                            </FormGroup>
                                                            {this.state.shareholders_agreement_status !== 'Missing' &&
                                                            <FormGroup>
                                                                <FileUpload
                                                                    onFileChange={file => {
                                                                        this.setState({shareholders_agreement_document: file})
                                                                    }} />
                                                            </FormGroup>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                <hr className="my-4" />
                                                <h6 className="heading-small text-muted mb-4">
                                                    Share Details
                                                </h6>
                                                <div className="pl-lg-4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-certificate-number"
                                                        >
                                                            Certificate Number
                                                        </label>
                                                        <Input
                                                            onChange={e => this.setState({certificate_number: e.target.value})}
                                                            value={this.state.certificate_number}
                                                            className="form-control-alternative"
                                                            id="input-certificate-number"
                                                            placeholder="0"
                                                            type="number"
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-share-number"
                                                        >
                                                            Number of Shares
                                                        </label>
                                                        <Input
                                                            onChange={e => this.setState({number_of_shares: e.target.value})}
                                                            value={this.state.number_of_shares}
                                                            className="form-control-alternative"
                                                            id="input-share-number"
                                                            placeholder="0"
                                                            type="number"
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-paid-per-share"
                                                        >
                                                            Paid Per Share
                                                        </label>
                                                        <Input
                                                            onChange={e => this.setState({paid_per_share: e.target.value})}
                                                            value={this.state.paid_per_share}
                                                            className="form-control-alternative"
                                                            id="input-paid-per-share"
                                                            placeholder="0"
                                                            type="number"
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-unpaid-per-share"
                                                        >
                                                            Unpaid Per Share
                                                        </label>
                                                        <Input
                                                            onChange={e => this.setState({unpaid_per_share: e.target.value})}
                                                            value={this.state.unpaid_per_share}
                                                            className="form-control-alternative"
                                                            id="input-unpaid-per-share"
                                                            placeholder="0"
                                                            type="number"
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label
                                                            className="form-control-label"
                                                            htmlFor="input-purchase-date"
                                                            md="2"
                                                        >
                                                            Issue Date
                                                        </Label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue="2018-11-23"
                                                            id="input-purchase-date"
                                                            type="date"
                                                            onChange={e => this.setState({purchaseDate: e.target.value})}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-share-type"
                                                        >
                                                            Share Type
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-share-type"
                                                            type="select"
                                                            onChange={e => this.setState({share_type: e.target.value})}
                                                        >
                                                            <option>Ordinary</option>
                                                            <option>Preference</option>
                                                        </Input>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-share-status"
                                                        >
                                                            Share Status
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            onChange={e => this.setState({share_certificate_status: e.target.value})}
                                                            id="input-share-status"
                                                            type="select"
                                                        >
                                                            <option>Missing</option>
                                                            <option>Need Signature</option>
                                                            <option>Complete</option>
                                                        </Input>
                                                    </FormGroup>
                                                    {this.state.share_certificate_status !== 'Missing' &&
                                                    <FormGroup>
                                                        <div className="custom-file">
                                                            <FileUpload onFileChange={(file) => {
                                                                this.setState({share_certificate_document: file})
                                                            }}/>
                                                        </div>
                                                    </FormGroup>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                        <SubmitButton
                                            waiting={this.state.processingForm}
                                        >
                                            Submit
                                        </SubmitButton>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default NewShares;