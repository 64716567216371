import React, { useState, useEffect }from "react";
import { API, Storage } from 'aws-amplify';

import { listNotes } from '../../graphql/queries';
import Header from "../../components/Headers/Header";
import {Card, CardBody, CardHeader, Container, Row} from "reactstrap";

import { createNote as createNoteMutation, deleteNote as deleteNoteMutation } from '../../graphql/mutations';

class notepad extends React.Component {

    state = {
        notes: [],
        noteName: '',
        noteDescription: '',
    };

    constructor(props) {
        super(props);

        //const initialFormState = { name: '', description: '' }
        //const [notes, setNotes] = useState([]);
        //const [formData, setFormData] = useState(initialFormState);

        this.fetchNotes = this.fetchNotes.bind(this);
        this.createNote = this.createNote.bind(this);
        this.deleteNote = this.deleteNote.bind(this);
        this.onChange = this.onChange.bind(this);

        this.fetchNotes().catch(err => {
            console.log('Could not fetch notes', err);
        });
    }

    async fetchNotes() {
        const apiData = await API.graphql({ query: listNotes });
        console.log('Fetching notes', apiData);
        const notesFromAPI = apiData.data.listNotes.items;

        await Promise.all(notesFromAPI.map(async note => {
            if (note.image) {
                const image = await Storage.get(note.image);
                note.image = image;
            }
            return note;
        }))

        this.setState({apiData: apiData.data, notes: notesFromAPI})
    }

    async createNote() {
        console.log('Creating note', this.state);
        if (!this.state.noteName || !this.state.noteDescription) return;

        let newNote = {name: this.state.noteName, description: this.state.noteDescription};

        if (this.state.noteImage) {
            console.log('Include image', this.state.noteImage);
            const image = await Storage.get(this.state.noteImage);
            newNote['image'] = image;
        }

        let notes = this.state.notes;
        notes.push(newNote);
        this.setState({notes: notes});

        API.graphql({ query: createNoteMutation, variables: { input: {
                    name: this.state.noteName,
                    description:this.state.Description,
                    image:this.state.noteImage
                }}}).then((r => {
            console.log('Created note', r);
        })).catch(err => {
            console.log('Failed to create note', err);
        });

        //setNotes([ ...notes, formData ]);
        //setFormData(initialFormState);
        this.setState({
            noteImage: null,
            noteDescription: '',
            noteName: '',
        })
    }

    async deleteNote({ id }) {
        const newNotesArray = this.state.notes.filter(note => note.id !== id);
        this.setState({notes: newNotesArray});
        await API.graphql({ query: deleteNoteMutation, variables: { input: { id } }});
    }

    async onChange(e) {
        if (!e.target.files[0]) return
        const file = e.target.files[0];
        this.setState({ noteImage: file.name });
        await Storage.put(file.name, file);
        this.fetchNotes();
    }


    render() {
        return (
            <>
                <Header />
                {/* Page content */}
                <Container className=" mt--7" fluid>
                    <Row>
                        <div className=" col">
                            <Card className=" shadow">
                                <CardHeader className=" bg-transparent">
                                    <h3 className=" mb-0">Notes</h3>
                                </CardHeader>
                                <CardBody>
                                    <input
                                        onChange={e => this.setState({noteName: e.target.value})}
                                        placeholder="Note name"
                                        value={this.state.noteName}
                                    />
                                    <input
                                        onChange={e => this.setState({ noteDescription: e.target.value})}
                                        placeholder="Note description"
                                        value={this.state.noteDescription}
                                    />
                                    <input
                                        type="file"
                                        onChange={this.onChange}
                                    />
                                    <button onClick={this.createNote}>Create Note</button>
                                    <div style={{marginBottom: 30}}>
                                        {
                                            this.state.notes.map(note => (
                                                <div key={note.id || note.name}>
                                                    <h2>{note.name}</h2>
                                                    <p>{note.description}</p>
                                                    {
                                                        note.image && <img src={note.image} style={{width: 400}} />
                                                    }
                                                    <button onClick={() => this.deleteNote(note)}>Delete note</button>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default notepad;