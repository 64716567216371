import React from "react";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/cjs/Button";

function SubmitButton(props) {
    const waiting = props.waiting;

    if(waiting){
        return (
            <Button
                color="info"
                type="submit"
                disabled
            >
                <div>
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    Submitting...
                </div>
            </Button>
        )
    } else {
        return (
            <Button
                color="info"
                type="submit">
                <div>
                    Submit
                </div>
            </Button>
        )
    }
}

export default SubmitButton;