/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

import {Component} from 'react';

import {signIn, signOut, signUp} from 'App/auth/auth_service';
import Authenticator from "aws-amplify-react";
import {Auth} from "aws-amplify";
import {Redirect, Route} from "react-router-dom";
import {history} from "../../_helpers/history";

class CreateAccount extends Component {

  state = {
    loading: true,
    isAuthenticated: false,
  }

  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
    this.state = {};
  }

  componentDidMount() {
    signOut();

    Auth.currentAuthenticatedUser().then((isAuthenticated) => {
      this.setState({
        loading: false,
        isAuthenticated,
      });
    }).catch((error) => {
      this.setState({
        loading: false,
        isAuthenticated: false,
      });
    });
  }

  handleFormSubmission(evt) {
    evt.preventDefault();

    const email = this.inputs.email;
    const password = this.inputs.password;

    Auth.signUp(email, password).then(user => {
      if(user != null){
        console.log('Login success', user);
        history.push('/');
      }
    });
  }

  onNewPasswordConfirm() {
    const user = this.state.user;
    const new_password = this.inputs.password;

    if(user == null || new_password == null) return;

    Auth.completeNewPassword( user, new_password, null)
        .then(user => {
          history.push('/');
        })
        .catch(err => {
          console.log(err);
        });
  }

  handleInputChange(evt) {
    this.inputs = this.inputs || {};
    const { name, value, type, checked } = evt.target;
    const check_type = ["radio", "checkbox"].includes(type);
    this.inputs[name] = check_type ? checked : value;
    this.inputs["checkedValue"] = check_type ? value : null;
    this.setState({ error: "" });
  }

  render() {
    const { component: Component, ...rest } = this.props;
    if (this.state.loading) {
      return <div>LOADING</div>;
    } else {
      return (
          <Route {...rest} render={props => (
              <div>
                {this.state.isAuthenticated && <Redirect to={{ pathname: '/', state: { from: this.props.location } }} />}
                <Col lg="5" md="7">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent pb-5">
                      <div className="text-center text-muted mb-4">
                        <h2>New User</h2>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <Form role="form">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                id="email"
                                key="email"
                                name="email"
                                onChange={this.handleInputChange}
                                placeholder="Email" type="email" autoComplete="new-email"/>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input id="password"
                                   key="password"
                                   name="password"
                                   onChange={this.handleInputChange}
                                   placeholder="Password" type="password" autoComplete="new-password"/>
                          </InputGroup>
                        </FormGroup>
                        <div className="text-center">
                          <Button className="my-4" color="primary" type="submit" onClick={this.handleFormSubmission}>
                            Sign in
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </div>
          )}
          />
      )
    }
  }
}

export default CreateAccount;
