/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      name
      description
      image
      createdAt
      updatedAt
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      name
      description
      image
      createdAt
      updatedAt
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      name
      description
      image
      createdAt
      updatedAt
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      parentID
      name
      description
      document_type
      createdAt
      updatedAt
      files {
        items {
          groupsCanAccess
          owner
          id
          documentID
          name
          description
          s3_location
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      parentID
      name
      description
      document_type
      createdAt
      updatedAt
      files {
        items {
          groupsCanAccess
          owner
          id
          documentID
          name
          description
          s3_location
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      parentID
      name
      description
      document_type
      createdAt
      updatedAt
      files {
        items {
          groupsCanAccess
          owner
          id
          documentID
          name
          description
          s3_location
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      documentID
      name
      description
      s3_location
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      documentID
      name
      description
      s3_location
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      documentID
      name
      description
      s3_location
      status
      createdAt
      updatedAt
    }
  }
`;
export const createPersonnel = /* GraphQL */ `
  mutation CreatePersonnel(
    $input: CreatePersonnelInput!
    $condition: ModelPersonnelConditionInput
  ) {
    createPersonnel(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      name
      former_name
      address
      date_of_birth
      country_of_birth
      start_date
      end_date
      createdAt
      updatedAt
      documents {
        items {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        nextToken
      }
      roles {
        items {
          groupsCanAccess
          owner
          id
          title
          start_date
          end_date
          personnelID
          consent_document_id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updatePersonnel = /* GraphQL */ `
  mutation UpdatePersonnel(
    $input: UpdatePersonnelInput!
    $condition: ModelPersonnelConditionInput
  ) {
    updatePersonnel(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      name
      former_name
      address
      date_of_birth
      country_of_birth
      start_date
      end_date
      createdAt
      updatedAt
      documents {
        items {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        nextToken
      }
      roles {
        items {
          groupsCanAccess
          owner
          id
          title
          start_date
          end_date
          personnelID
          consent_document_id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deletePersonnel = /* GraphQL */ `
  mutation DeletePersonnel(
    $input: DeletePersonnelInput!
    $condition: ModelPersonnelConditionInput
  ) {
    deletePersonnel(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      name
      former_name
      address
      date_of_birth
      country_of_birth
      start_date
      end_date
      createdAt
      updatedAt
      documents {
        items {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        nextToken
      }
      roles {
        items {
          groupsCanAccess
          owner
          id
          title
          start_date
          end_date
          personnelID
          consent_document_id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createPersonnelRole = /* GraphQL */ `
  mutation CreatePersonnelRole(
    $input: CreatePersonnelRoleInput!
    $condition: ModelPersonnelRoleConditionInput
  ) {
    createPersonnelRole(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      title
      start_date
      end_date
      personnelID
      consent_document_id
      createdAt
      updatedAt
      consent_document {
        items {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updatePersonnelRole = /* GraphQL */ `
  mutation UpdatePersonnelRole(
    $input: UpdatePersonnelRoleInput!
    $condition: ModelPersonnelRoleConditionInput
  ) {
    updatePersonnelRole(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      title
      start_date
      end_date
      personnelID
      consent_document_id
      createdAt
      updatedAt
      consent_document {
        items {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deletePersonnelRole = /* GraphQL */ `
  mutation DeletePersonnelRole(
    $input: DeletePersonnelRoleInput!
    $condition: ModelPersonnelRoleConditionInput
  ) {
    deletePersonnelRole(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      title
      start_date
      end_date
      personnelID
      consent_document_id
      createdAt
      updatedAt
      consent_document {
        items {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createShareholder = /* GraphQL */ `
  mutation CreateShareholder(
    $input: CreateShareholderInput!
    $condition: ModelShareholderConditionInput
  ) {
    createShareholder(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      name
      address
      start_date
      end_date
      shareholder_agreement_id
      createdAt
      updatedAt
      shareholder_agreement {
        groupsCanAccess
        owner
        id
        parentID
        name
        description
        document_type
        createdAt
        updatedAt
        files {
          nextToken
        }
      }
      documents {
        items {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        nextToken
      }
      shares {
        items {
          groupsCanAccess
          owner
          id
          certificate_number
          owner_id
          number_of_shares
          purchase_date
          share_type
          paid_per_share
          unpaid_per_share
          share_certificate_id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateShareholder = /* GraphQL */ `
  mutation UpdateShareholder(
    $input: UpdateShareholderInput!
    $condition: ModelShareholderConditionInput
  ) {
    updateShareholder(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      name
      address
      start_date
      end_date
      shareholder_agreement_id
      createdAt
      updatedAt
      shareholder_agreement {
        groupsCanAccess
        owner
        id
        parentID
        name
        description
        document_type
        createdAt
        updatedAt
        files {
          nextToken
        }
      }
      documents {
        items {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        nextToken
      }
      shares {
        items {
          groupsCanAccess
          owner
          id
          certificate_number
          owner_id
          number_of_shares
          purchase_date
          share_type
          paid_per_share
          unpaid_per_share
          share_certificate_id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteShareholder = /* GraphQL */ `
  mutation DeleteShareholder(
    $input: DeleteShareholderInput!
    $condition: ModelShareholderConditionInput
  ) {
    deleteShareholder(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      name
      address
      start_date
      end_date
      shareholder_agreement_id
      createdAt
      updatedAt
      shareholder_agreement {
        groupsCanAccess
        owner
        id
        parentID
        name
        description
        document_type
        createdAt
        updatedAt
        files {
          nextToken
        }
      }
      documents {
        items {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        nextToken
      }
      shares {
        items {
          groupsCanAccess
          owner
          id
          certificate_number
          owner_id
          number_of_shares
          purchase_date
          share_type
          paid_per_share
          unpaid_per_share
          share_certificate_id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createShares = /* GraphQL */ `
  mutation CreateShares(
    $input: CreateSharesInput!
    $condition: ModelSharesConditionInput
  ) {
    createShares(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      certificate_number
      owner_id
      number_of_shares
      purchase_date
      share_type
      paid_per_share
      unpaid_per_share
      share_certificate_id
      createdAt
      updatedAt
      documents {
        items {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        nextToken
      }
      share_certificate {
        groupsCanAccess
        owner
        id
        parentID
        name
        description
        document_type
        createdAt
        updatedAt
        files {
          nextToken
        }
      }
      shareholder {
        groupsCanAccess
        owner
        id
        name
        address
        start_date
        end_date
        shareholder_agreement_id
        createdAt
        updatedAt
        shareholder_agreement {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        shares {
          nextToken
        }
      }
    }
  }
`;
export const updateShares = /* GraphQL */ `
  mutation UpdateShares(
    $input: UpdateSharesInput!
    $condition: ModelSharesConditionInput
  ) {
    updateShares(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      certificate_number
      owner_id
      number_of_shares
      purchase_date
      share_type
      paid_per_share
      unpaid_per_share
      share_certificate_id
      createdAt
      updatedAt
      documents {
        items {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        nextToken
      }
      share_certificate {
        groupsCanAccess
        owner
        id
        parentID
        name
        description
        document_type
        createdAt
        updatedAt
        files {
          nextToken
        }
      }
      shareholder {
        groupsCanAccess
        owner
        id
        name
        address
        start_date
        end_date
        shareholder_agreement_id
        createdAt
        updatedAt
        shareholder_agreement {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        shares {
          nextToken
        }
      }
    }
  }
`;
export const deleteShares = /* GraphQL */ `
  mutation DeleteShares(
    $input: DeleteSharesInput!
    $condition: ModelSharesConditionInput
  ) {
    deleteShares(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      certificate_number
      owner_id
      number_of_shares
      purchase_date
      share_type
      paid_per_share
      unpaid_per_share
      share_certificate_id
      createdAt
      updatedAt
      documents {
        items {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        nextToken
      }
      share_certificate {
        groupsCanAccess
        owner
        id
        parentID
        name
        description
        document_type
        createdAt
        updatedAt
        files {
          nextToken
        }
      }
      shareholder {
        groupsCanAccess
        owner
        id
        name
        address
        start_date
        end_date
        shareholder_agreement_id
        createdAt
        updatedAt
        shareholder_agreement {
          groupsCanAccess
          owner
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        documents {
          nextToken
        }
        shares {
          nextToken
        }
      }
    }
  }
`;
export const createContractor = /* GraphQL */ `
  mutation CreateContractor(
    $input: CreateContractorInput!
    $condition: ModelContractorConditionInput
  ) {
    createContractor(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      name
      Address
      NDA_status
      IP_status
      Workers_Comp_status
      createdAt
      updatedAt
    }
  }
`;
export const updateContractor = /* GraphQL */ `
  mutation UpdateContractor(
    $input: UpdateContractorInput!
    $condition: ModelContractorConditionInput
  ) {
    updateContractor(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      name
      Address
      NDA_status
      IP_status
      Workers_Comp_status
      createdAt
      updatedAt
    }
  }
`;
export const deleteContractor = /* GraphQL */ `
  mutation DeleteContractor(
    $input: DeleteContractorInput!
    $condition: ModelContractorConditionInput
  ) {
    deleteContractor(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      name
      Address
      NDA_status
      IP_status
      Workers_Comp_status
      createdAt
      updatedAt
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      name
      address
      createdAt
      updatedAt
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      name
      address
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      name
      address
      createdAt
      updatedAt
    }
  }
`;
export const createCompanyDetails = /* GraphQL */ `
  mutation CreateCompanyDetails(
    $input: CreateCompanyDetailsInput!
    $condition: ModelCompanyDetailsConditionInput
  ) {
    createCompanyDetails(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      name
      address
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyDetails = /* GraphQL */ `
  mutation UpdateCompanyDetails(
    $input: UpdateCompanyDetailsInput!
    $condition: ModelCompanyDetailsConditionInput
  ) {
    updateCompanyDetails(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      name
      address
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyDetails = /* GraphQL */ `
  mutation DeleteCompanyDetails(
    $input: DeleteCompanyDetailsInput!
    $condition: ModelCompanyDetailsConditionInput
  ) {
    deleteCompanyDetails(input: $input, condition: $condition) {
      groupsCanAccess
      owner
      id
      name
      address
      createdAt
      updatedAt
    }
  }
`;
