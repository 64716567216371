import React, { useState, useEffect }from "react";
import {API, Auth, Storage} from 'aws-amplify';

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Progress,
    Row,
    CardImg,
    CardTitle,
    FormGroup,
    Form,
    Input,
    ListGroupItem,
    ListGroup,
} from "reactstrap";
import Header from "../components/Headers/Header";

class settings extends React.Component {

    state = {
        userInfo: {},
        userName: "",
        userEmail: "",
        companyName: "",
        companyAddress: "1 Northbourne Ave",
        companyCity: "Canberra",
        companyCountry: "Australia",
        companyPostCode: "",
        status: ""
    };

    constructor(props) {
        super(props);

        this.fetchCurrentUserInfo = this.fetchCurrentUserInfo.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.fetchCurrentUserInfo();
    }

    fetchCurrentUserInfo() {
        Auth.currentUserInfo().then(r => {
            console.log("Got User Info", r);
            this.setState({
                userInfo: r,
                userName: r.attributes['custom:Name'] || "",
                userEmail: r.attributes['email'],
                companyAddress: r.attributes['custom:Address'] || "",
                companyName: r.attributes['custom:CompanyName'] || ""
            });
        }).catch(e => {
            console.log("Failed to get user info", e);
        })
    }

    handleSubmit(evt){
        evt.preventDefault();

        Auth.currentAuthenticatedUser().then(user => {
            Auth.updateUserAttributes(user, {
                'custom:Name': this.state.userName,
                'custom:CompanyName': this.state.companyName,
                'custom:companyAddress': this.state.companyAddress,
                'custom:companyCity': this.state.companyCity,
                'custom:companyCountry': this.state.companyCountry,
                'custom:companyPostCode': this.state.companyPostCode
            }).then(updateResult => {
                this.setState({status: 'Success'});
                console.log('Updated User Attributes');
            }).catch(e => console.log('Failed to update user', e));
        })
    }

    render() {
        const userInfo = this.state.userInfo;

        return (
            <>
                <Header />
                <Container className="mt--6" fluid>
                    <Row>
                        <Col className="order-xl-1">
                            <Card>
                                <CardHeader>
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Edit profile</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={this.handleSubmit}>
                                        <h6 className="heading-small text-muted mb-4">
                                            User information
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-name"
                                                        >
                                                            Name
                                                        </label>
                                                        <Input
                                                            onChange={e => this.setState({userName: e.target.value})}
                                                            value={this.state.userName}
                                                            id="input-name"
                                                            placeholder="Name"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-email"
                                                        >
                                                            Email address
                                                        </label>
                                                        <Input
                                                            disabled
                                                            id="input-email"
                                                            placeholder="user@securetary.com"
                                                            type="email"
                                                            onChange={e => this.setState({userEmail: e.target.value})}
                                                            value={this.state.userEmail}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-company-name"
                                                        >
                                                            Company Name
                                                        </label>
                                                        <Input
                                                            id="input-company-name"
                                                            placeholder="My Company"
                                                            type="text"
                                                            onChange={e => this.setState({companyName: e.target.value})}
                                                            value={this.state.companyName}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr className="my-4" />

                                        <h6 className="heading-small text-muted mb-4">
                                            Contact information
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-address"
                                                        >
                                                            Address
                                                        </label>
                                                        <Input
                                                            onChange={e => this.setState({companyAddress: e.target.value})}
                                                            value={this.state.companyAddress}
                                                            id="input-address"
                                                            placeholder="Home Address"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-city"
                                                        >
                                                            City
                                                        </label>
                                                        <Input
                                                            onChange={e => this.setState({companyCity: e.target.value})}
                                                            value={this.state.companyCity}
                                                            id="input-city"
                                                            placeholder="City"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-country"
                                                        >
                                                            Country
                                                        </label>
                                                        <Input
                                                            onChange={e => this.setState({companyCountry: e.target.value})}
                                                            value={this.state.companyCountry}
                                                            id="input-country"
                                                            placeholder="Country"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-country"
                                                        >
                                                            Postal code
                                                        </label>
                                                        <Input
                                                            onChange={e => this.setState({companyPostCode: e.target.value})}
                                                            id="input-postal-code"
                                                            placeholder="Postal code"
                                                            type="number"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Button
                                            color="info"
                                            type="submit"
                                        >
                                            Update
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default settings;