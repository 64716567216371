import React, { useState, useEffect }from "react";
import {API, Auth, Storage} from 'aws-amplify';

import {getDocument, listNotes} from '../../graphql/queries';
import Header from "../../components/Headers/Header";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";

import {
    createDocument as createDocumentMutation,
    createFile as createFileMutation, createPersonnel, createPersonnelRole
} from '../../graphql/mutations';
import Dropzone from "react-dropzone";
import CardsFooter from "../../components/Footers/CardsFooter";
import {Document} from "react-pdf";
import { v4 as uuidv4 } from 'uuid';
import {listDocumentsAndFiles} from "../../graphql/custom_queries";
import SubmitButton from "../../components/CustomButtons/SubmitButton";
import FileUpload from "../../components/CustomUpload/FileUpload";
import {awsInteraction} from "../../util/graphQL";

class NewMember extends React.Component {

    state = {
        memberName: "",
        formerName: "",
        memberAddress: "",
        roles: [],
        startDate: "2018-11-18",
        dateOfBirth: "2018-11-18",
        countryOfBirth: "Australia",
        secretary_role: false,
        director_role: false,
        chairman_role: false,
        secretary_consent_status: 'Missing',
        director_consent_status: 'Missing',
        chairman_consent_status: 'Missing',
        secretaryConsentDocument: null,
        directorConsentDocument: null,
        chairmanConsentDocument: null,
        processingForm: false
    };

    constructor(props) {
        super(props);

        this.createMember = this.createMember.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        Auth.currentAuthenticatedUser().then(r => (
            this.setState({username: r.username})
        ));
    }

    checkValidSubmission(){
        if(!this.state.username){
            return false;
        }

        if(!this.state.secretary_role && !this.state.director_role && !this.state.chairman_role){
            return false;
        }

        if(this.state.memberName === ""){
            console.log("Member Name Missing");
            return false;
        }

        if(this.state.memberAddress === ""){
            console.log("Member Address Missing");
            return false;
        }

        if(this.state.countryOfBirth === ""){
            console.log("Member DoB Missing");
            return false;
        }

        //TODO: No uploaded file when document status is not missing

        return true;
    }

    async createRole(roleName, personnelID, consent_status, consent_document){
        let documentName = this.state.memberName + " " + roleName + " consent";
        let documentDescription = "Consent document for " + this.state.memberName + " to become a " + roleName + " of the company";

        let consentDocumentResponse = await awsInteraction.createDocument(documentName, documentDescription);

        console.log(consentDocumentResponse);

        if(!consentDocumentResponse){
            return null;
        }

        let personnelRoleResponse = await API.graphql({
            query: createPersonnelRole,
            variables: {input: {
                    title: roleName,
                    personnelID: personnelID,
                    start_date: this.state.startDate,
                    consent_document_id: consentDocumentResponse.data.createDocument.id,
                    groupsCanAccess: []
                }}
        }).catch(error => {
            console.log("Failed to create " + roleName + " role", error);
            return null;
        });

        if(consent_status !== 'Missing' && consent_document != null){
            let fileName = "Consent Document " + consent_status;
            let fileDescription = "";

            let file_status = consent_status;
            let file = consent_document;

            let secretaryConsentFileResponse = await awsInteraction.createFile(fileName, fileDescription,
                file, file_status, consentDocumentResponse.data.createDocument.id);

            if(!secretaryConsentFileResponse){
                return null;
            }
        }

        return consentDocumentResponse;
    }

    async createMember() {
        if(!this.checkValidSubmission()) {
            console.log('Something is missing in the submission form');
            return;
        }

        this.setState({processingForm: true});

        let new_personnel_input = {
            name: this.state.memberName,
            address: this.state.memberAddress,
            start_date: this.state.startDate,
            date_of_birth: this.state.dateOfBirth,
            country_of_birth: this.state.countryOfBirth,
            groupsCanAccess: []
        };

        if(this.state.formerName && this.state.formerName !== ""){
            new_personnel_input.former_name = this.state.formerName;
        }

        //Create personnel
        let personnelResponse = await API.graphql({
            query: createPersonnel,
            variables: {input: new_personnel_input}
        }).catch(err => {
            console.log("Could not create new personnel", err);
            this.setState({processingForm: false});
        });

        let personnelID = personnelResponse.data.createPersonnel.id;

        if(this.state.secretary_role) {
            let secretaryCreateResponse = await this.createRole("Secretary", personnelID,
                this.state.secretary_consent_status, this.state.secretaryConsentDocument);

            if(!secretaryCreateResponse){
                this.setState({processingForm: false});
                return;
            }
        }

        if(this.state.director_role){
            let directorCreateResponse = await this.createRole("Director", personnelID,
                this.state.director_consent_status, this.state.directorConsentDocument);

            if(!directorCreateResponse){
                this.setState({processingForm: false});
                return;
            }
        }

        if(this.state.chairman_role){
            let chairmanCreateResponse = await this.createRole("Chairman", personnelID,
                this.state.chairman_consent_status, this.state.chairmanConsentDocument);

            if(!chairmanCreateResponse){
                this.setState({processingForm: false});
                return;
            }
        }

        this.setState({processingForm: false}, () => {
            this.props.history.push('/company');
        });
    }

    handleSubmit(event) {
        this.createMember().catch(err => {
            console.log(err);
        });

        event.preventDefault();
    }

    render() {
        return (
            <>
                <Header />
                {/* Page content */}
                <Container className=" mt--7" fluid>
                    <Row>
                        <div className=" col">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <h3 className="mb-0">New Public Officer</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={this.handleSubmit}>
                                        <h6 className="heading-small text-muted mb-4">
                                            User information
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            Full Name
                                                        </label>
                                                        <Input
                                                            onChange={e => this.setState({memberName: e.target.value})}
                                                            value={this.state.memberName}
                                                            className="form-control-alternative"
                                                            id="input-username"
                                                            placeholder="John Doe"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-former-name"
                                                        >
                                                            Former Name (if any)
                                                        </label>
                                                        <Input
                                                            onChange={e => this.setState({formerName: e.target.value})}
                                                            value={this.state.formerName}
                                                            className="form-control-alternative"
                                                            id="input-former-name"
                                                            placeholder="John Doe"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-email"
                                                        >
                                                            Residential Address
                                                        </label>
                                                        <Input
                                                            onChange={e => this.setState({memberAddress: e.target.value})}
                                                            value={this.state.memberAddress}
                                                            className="form-control-alternative"
                                                            id="input-email"
                                                            placeholder="1 Northbourne Ave Canberra 2600"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-email"
                                                        >
                                                            Country of Birth
                                                        </label>
                                                        <Input
                                                            onChange={e => this.setState({countryOfBirth: e.target.value})}
                                                            value={this.state.countryOfBirth}
                                                            className="form-control-alternative"
                                                            id="input-email"
                                                            placeholder="Australia"
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <Label
                                                            className="form-control-label"
                                                            htmlFor="input-start-date"
                                                            md="2"
                                                        >
                                                            Date of Birth
                                                        </Label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue="2018-11-23"
                                                            id="input-start-date"
                                                            type="date"
                                                            onChange={e => this.setState({dateOfBirth: e.target.value})}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <hr className="my-4" />
                                        <h6 className="heading-small text-muted mb-4">
                                            User Role information
                                        </h6>
                                        <div className="pl-lg-4">
                                            <div className="custom-control custom-checkbox mb-3">
                                                <input
                                                    className="custom-control-input"
                                                    id="checkSecretary"
                                                    name="custom-radio-1"
                                                    type="checkbox"
                                                    value="Secretary"
                                                    onChange={e => this.setState({secretary_role: e.target.checked})}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="checkSecretary"
                                                >
                                                    Secretary
                                                </label>
                                            </div>
                                            {this.state.secretary_role &&
                                            <Row>
                                                <Col lg="6">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="exampleFormControlSelect3"
                                                    >
                                                        Consent Document Status
                                                    </label>
                                                    <Input
                                                        onChange={e => this.setState({secretary_consent_status: e.target.value})}
                                                        id="exampleFormControlSelect3"
                                                        type="select">
                                                        <option>Missing</option>
                                                        <option>Need Signature</option>
                                                        <option>Complete</option>
                                                    </Input>
                                                </Col>
                                            </Row>
                                            }
                                            {this.state.secretary_role && this.state.secretary_consent_status !== 'Missing' &&
                                            <Row>
                                                <Col lg="6">
                                                    <FileUpload
                                                        onFileChange={file => {
                                                            this.setState({secretaryConsentDocument: file})
                                                        }} />
                                                </Col>
                                            </Row>
                                            }
                                            <div className="custom-control custom-checkbox mb-3">
                                                <input
                                                    className="custom-control-input"
                                                    id="checkDirector"
                                                    name="custom-radio-1"
                                                    type="checkbox"
                                                    value="Director"
                                                    onChange={e => this.setState({director_role: e.target.checked})}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="checkDirector"
                                                >
                                                    Director
                                                </label>
                                            </div>
                                            {this.state.director_role &&
                                            <Row>
                                                <Col lg="6">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="exampleFormControlSelect3"
                                                    >
                                                        Consent Document Status
                                                    </label>
                                                    <Input
                                                        onChange={e => this.setState({director_consent_status: e.target.value})}
                                                        id="exampleFormControlSelect3"
                                                        type="select">
                                                        <option>Missing</option>
                                                        <option>Need Signature</option>
                                                        <option>Complete</option>
                                                    </Input>
                                                </Col>
                                            </Row>
                                            }
                                            {this.state.director_role &&this.state.director_consent_status !== 'Missing' &&
                                            <Row>
                                                <Col lg="6">
                                                    <FileUpload
                                                        onFileChange={file => {
                                                            this.setState({directorConsentDocument: file})
                                                        }} />
                                                </Col>
                                            </Row>
                                            }
                                            <div className="custom-control custom-checkbox mb-3">
                                                <input
                                                    className="custom-control-input"
                                                    id="checkChairman"
                                                    name="custom-radio-1"
                                                    type="checkbox"
                                                    value="Chairman"
                                                    onChange={e => this.setState({chairman_role: e.target.checked})}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="checkChairman"
                                                >
                                                    Chairman
                                                </label>
                                            </div>
                                            {this.state.chairman_role &&
                                            <Row>
                                                <Col lg="6">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="exampleFormControlSelect3"
                                                    >
                                                        Consent Document Status
                                                    </label>
                                                    <Input
                                                        onChange={e => this.setState({chairman_consent_status: e.target.value})}
                                                        id="exampleFormControlSelect3"
                                                        type="select">
                                                        <option>Missing</option>
                                                        <option>Need Signature</option>
                                                        <option>Complete</option>
                                                    </Input>
                                                </Col>
                                            </Row>
                                            }
                                            {this.state.chairman_role && this.state.chairman_consent_status !== 'Missing' &&
                                            <Row>
                                                <Col lg="6">
                                                    <FileUpload
                                                        onFileChange={file => {
                                                            this.setState({chairmanConsentDocument: file})
                                                        }} />
                                                </Col>
                                            </Row>
                                            }
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <Label
                                                            className="form-control-label"
                                                            htmlFor="input-start-date"
                                                            md="2"
                                                        >
                                                            Start Date
                                                        </Label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            defaultValue="2018-11-23"
                                                            id="input-start-date"
                                                            type="date"
                                                            onChange={e => this.setState({startDate: e.target.value})}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <SubmitButton
                                            waiting={this.state.processingForm}
                                        >
                                            Submit
                                        </SubmitButton>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default NewMember;