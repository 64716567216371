/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:016b618e-8d63-4b7e-83da-b4afd046de0f",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_AhXm9zEl9",
    "aws_user_pools_web_client_id": "18ivlqdv0b8r08vc1eec1sbdin",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://esscrrbep5fldlpwhtw4p37uui.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://xit0di9b7k.execute-api.ap-southeast-2.amazonaws.com/dev",
            "region": "ap-southeast-2"
        }
    ],
    "aws_user_files_s3_bucket": "securetary20e42c92c2a447739aceedf8bd9839e0201939-dev",
    "aws_user_files_s3_bucket_region": "ap-southeast-2",
    "aws_mobile_analytics_app_id": "6674ca3b8396459d9d2d18c484bdb5ca",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
