import React, { useState, useEffect }from "react";
import {API, Auth, Storage} from 'aws-amplify';

import {getDocument, listNotes} from '../../graphql/queries';
import Header from "../../components/Headers/Header";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row} from "reactstrap";

import {
    createDocument as createDocumentMutation,
    createFile as createFileMutation
} from '../../graphql/mutations';
import Dropzone from "react-dropzone";
import CardsFooter from "../../components/Footers/CardsFooter";
import {Document} from "react-pdf";
import { v4 as uuidv4 } from 'uuid';
import {listDocumentsAndFiles} from "../../graphql/custom_queries";
import {awsInteraction} from "../../util/graphQL";
import SubmitButton from "../../components/CustomButtons/SubmitButton";

class NewFileVersion extends React.Component {

    state = {
        fileName: '',
        fileDescription: '',
        file: null,
        status: 'Complete',
        processingForm: false
    };

    constructor(props) {
        super(props);

        this.createFile = this.createFile.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async createFile() {
        if (!this.state.fileName || !this.state.fileDescription || !this.state.file) return;

        this.setState({processingForm: true});

        let doc_response = await API.graphql({
            query: listDocumentsAndFiles
        }).catch(error => {
            console.log("Could not get documents", error);
            this.setState({processingForm: false});
        })

        let hasDocument = false;
        let doc;

        for(doc in doc_response.data.listDocuments.items){
            if(doc_response.data.listDocuments.items[doc].id === this.props.match.params.id){
                hasDocument = true;
                break;
            }
        }

        if(!hasDocument){
            console.log("Could not find file");
            this.setState({processingForm: false});
            return;
        }

        let fileUploadResponse = await awsInteraction.createFile(this.state.fileName, this.state.fileDescription,
            this.state.file, this.state.status, this.props.match.params.id);

        this.setState({processingForm: false});

        if(!fileUploadResponse){
            return null;
        }

         this.props.history.push('/company');
    }

    handleSubmit(event) {
        this.createFile().catch(err => {
            console.log(err);
        });

        event.preventDefault();
    }

    render() {

        return (
            <>
                <Header />
                {/* Page content */}
                <Container className=" mt--7" fluid>
                    <Row>
                        <div className=" col">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <h3 className="mb-0">New File</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={this.handleSubmit}>
                                        <Row>
                                            <Col>
                                                <h6 className="heading-small text-muted mb-4">
                                                    File Description
                                                </h6>
                                                <div className="pl-lg-4">
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-username"
                                                                >
                                                                    Filename
                                                                </label>
                                                                <Input
                                                                    onChange={e => this.setState({fileName: e.target.value})}
                                                                    value={this.state.fileName}
                                                                    className="form-control-alternative"
                                                                    id="input-username"
                                                                    placeholder="filename"
                                                                    type="text"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-email"
                                                                >
                                                                    Description
                                                                </label>
                                                                <Input
                                                                    onChange={e => this.setState({fileDescription: e.target.value})}
                                                                    value={this.state.fileDescription}
                                                                    className="form-control-alternative"
                                                                    id="input-email"
                                                                    placeholder="description"
                                                                    type="text"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="documentStatusDropdown"
                                                                >
                                                                    File Status
                                                                </label>
                                                                <Input
                                                                    onChange={e => this.setState({status: e.target.value})}
                                                                    id="documentStatusDropdown"
                                                                    type="select">
                                                                    <option>Complete</option>
                                                                    <option>Need Signature</option>
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col>
                                                {/* Description */}
                                                <h6 className="heading-small text-muted mb-4">File Upload</h6>
                                                <div className="pl-lg-4">
                                                    <FormGroup>
                                                        <label>File Upload</label>
                                                        {!this.state.file &&
                                                        <Dropzone
                                                            onDrop={acceptedFiles => this.setState({file: acceptedFiles[0]})}
                                                            maxFiles={1} accept={'.pdf'}>
                                                            {({getRootProps, getInputProps}) => (
                                                                <section>
                                                                    <div {...getRootProps()}>
                                                                        <input {...getInputProps()} />
                                                                        <p>Drag 'n' drop some files here, or click to
                                                                            select files</p>
                                                                    </div>
                                                                </section>
                                                            )}
                                                        </Dropzone>
                                                        }
                                                        {this.state.file &&
                                                        <div>
                                                            <p>{this.state.file.name}</p>
                                                            <Button
                                                                color="primary"
                                                                size="sm"
                                                                onClick={e => (this.setState({file: null}))}
                                                            >
                                                                Remove
                                                            </Button>
                                                        </div>
                                                        }
                                                    </FormGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                        <SubmitButton
                                            waiting={this.state.processingForm}
                                        >
                                            Submit
                                        </SubmitButton>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default NewFileVersion;