import React from "react";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/cjs/Button";
import {awsInteraction} from "../util/graphQL";
import {Badge} from "reactstrap";

class DocumentStatus extends React.Component{
    state = {
        status: ""
    }

    constructor(props) {
        super(props);

        if(props.documentID) {
            this.getStatus(props.documentID);
        } else if(props.files){
            if(props.files.length > 0){
                this.state.status =  props.files[props.files.length - 1].status;
            } else {
                this.state.status = "Missing";
            }
        }

        if(this.state.status === "Complete"){
            this.state.bg_class = "bg-success";
        } else {
            this.state.bg_class = "bg-warning"
        }
    }

    async getStatus(id) {
        let status = await awsInteraction.getDocumentStatus(id);
        this.setState({status: status});
    }

    render(){
        return(
            <Badge color="" className="badge-dot mr-4">
                <i className={this.state.bg_class} />
                <span className="status">{this.state.status}</span>
            </Badge>
        )
    }
}

export default DocumentStatus;