import React from 'react';
import { Page, Document, Image, StyleSheet } from '@react-pdf/renderer';
import ShareCertificateTitle from "./Components/ShareCertificateTitle";
import IssueCompanyDetails from "./Components/IssueCompanyDetails";
import ReceivingCompanyDetails from "./Components/ReceivingCompanyDetails";
import ShareCertificateFooter from "./Components/ShareCertificateFooter";
import ShareCertificateTable from "./Components/ShareCertificateTable";
import ShareCertificateSigniture from "./Components/ShareCertificateSigniture";

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
  });
  
  const ShareCertificate = ({shareCertificateDetails}) => (
            <Document>
                <Page size="A4" style={styles.page}>
                    <ShareCertificateTitle title='Share Certificate'/>
                    <IssueCompanyDetails shareCertificateDetails={shareCertificateDetails}/>
                    <ReceivingCompanyDetails shareCertificateDetails={shareCertificateDetails}/>
                    <ShareCertificateTable shareCertificateDetails={shareCertificateDetails} />
                    <ShareCertificateSigniture />
                    <ShareCertificateFooter />
                </Page>
            </Document>
        );
  
  export default ShareCertificate