import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({

    titleContainer:{
        flexDirection: 'row',
        marginTop: 12
    },
    reportTitle:{
        fontSize: 12,
        textAlign: 'left',
    }
});


const ShareCertificateSigniture = () => (
    <Fragment>
        <View style={styles.titleContainer}>
            <Text style={styles.reportTitle}>Date: __________________________________________________________</Text>
        </View>
        <View style={styles.titleContainer}>
            <Text style={styles.reportTitle}>Company Officer Name: ___________________________________________</Text>
        </View>
        <View style={styles.titleContainer}>
            <Text style={styles.reportTitle}>Company Officer Signiture: _________________________________________</Text>
        </View>
    </Fragment>
);

export default ShareCertificateSigniture