import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import ShareCertificateTableHeader from "./ShareCertificateTableHeader";
import ShareCertificateTableRow from "./ShareCertificateTableRow";

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
});

  const ShareCertificateTable = ({shareCertificateDetails}) => (
    <View style={styles.tableContainer}>
        <ShareCertificateTableHeader />
        <ShareCertificateTableRow items={shareCertificateDetails.items} />
    </View>
  );
  
  export default ShareCertificateTable