import React, { useState, useEffect }from "react";
import { API, Storage } from 'aws-amplify';

import Header from "../components/Headers/Header";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label,
    Media, Progress,
    Row,
    Table, UncontrolledDropdown,
    UncontrolledTooltip
} from "reactstrap";
import {getDocument, getPersonnel, getShareholder} from "../graphql/queries";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import {displayAWSDate, displayAWSDateTime} from "../util/time";
import {getDocumentByParentID, getPersonnelAndRoles} from "../graphql/custom_queries";
import {getDocumentStatus} from "../util/graphQL";
import DocumentStatus from "../components/DocumentStatus";

class Officer extends React.Component {

    state = {
        officer: {},
        downloadPath: ''
    };

    constructor(props) {
        super(props);

        this.fetchOfficer = this.fetchOfficer.bind(this);
        this.getOfficerDetail = this.getOfficerDetail.bind(this);

        this.fetchOfficer().catch(err => {
            console.log('Could not fetch officer', err);
        });
    }

    async fetchOfficer(){
        const apiData = await API.graphql({
            query: getPersonnelAndRoles,
            variables: {
                id: this.props.match.params.id
            }
        });

        const PersonnelFromAPI = apiData.data.getPersonnel;

        console.log("officer", PersonnelFromAPI);

        this.setState({apiData: apiData.data, officer: PersonnelFromAPI})
    }

    getOfficerDetail(detail){
        if(!this.state.officer){
            return "";
        } else {
            return detail;
        }
    }

    onShareholderAgreementClick(id){

    }

    onShareClick(id){

    }

    secretaryRow(officer){
        if(officer.roles.includes("Secretary")){
            let consent_document;

            for(let document of officer.documents){
                if(document.name===officer.name + " Secretary Consent"){
                    consent_document = document;
                    break;
                }
            }

            if(!consent_document) return;

            return (
                <tr>
                    <td scope="col">Secretary</td>
                    <td scope="col">Start Date</td>
                    <td scope="col">End Date</td>
                    <td scope="col">Consent Status</td>
                </tr>
            )
        } else {
            return;
        }
    }

    directorRow(officer){

    }

    chairmanRow(officer){

    }

    onRoleClick(role){

    }

    getDocumentStatus(Role){
        return "Status";
    }

    render() {
        let officer = this.state.officer;
        return (
            <>
                <Header />
                {/* Page content */}
                <Container className=" mt--7" fluid>
                    <Row>
                        <div className=" col">
                            <Card className=" shadow">
                                <CardHeader className=" bg-transparent">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Public Officer</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <h6 className="heading-small text-muted mb-4">
                                        Personal Details
                                    </h6>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-name"
                                                >
                                                    Full Name
                                                </label>
                                                <Input
                                                    disabled
                                                    id="input-name"
                                                    placeholder="Jane Doe"
                                                    type="text"
                                                    value={this.getOfficerDetail(officer.name)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-name"
                                                >
                                                    Former Name
                                                </label>
                                                <Input
                                                    disabled
                                                    id="input-name"
                                                    placeholder=""
                                                    type="text"
                                                    value={this.getOfficerDetail(officer.former_name)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <Label
                                                    className="form-control-label"
                                                    htmlFor="input-date-of-birth"
                                                >
                                                    Date of Birth
                                                </Label>
                                                <Input
                                                    disabled
                                                    className="form-control-alternative"
                                                    defaultValue="2018-11-23"
                                                    id="input-date-of-birth"
                                                    type="date"
                                                    value={this.getOfficerDetail(officer.date_of_birth)}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-country-of-birth"
                                                >
                                                    Country of Birth
                                                </label>
                                                <Input
                                                    disabled
                                                    value={this.getOfficerDetail(officer.country_of_birth)}
                                                    className="form-control-alternative"
                                                    id="input-country-of-birth"
                                                    placeholder="Shareholder Address"
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-shareholderaddress"
                                                >
                                                    Address
                                                </label>
                                                <Input
                                                    disabled
                                                    value={this.getOfficerDetail(officer.address)}
                                                    className="form-control-alternative"
                                                    id="input-shareholderaddress"
                                                    placeholder="Shareholder Address"
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <Label
                                                    className="form-control-label"
                                                    htmlFor="input-purchase-date"
                                                >
                                                    Start Date
                                                </Label>
                                                <Input
                                                    disabled
                                                    className="form-control-alternative"
                                                    defaultValue="2018-11-23"
                                                    id="input-purchase-date"
                                                    type="date"
                                                    value={this.getOfficerDetail(officer.start_date)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <h6 className="heading-small text-muted mb-4">
                                        Roles
                                    </h6>
                                    <Row>
                                        <Col>
                                            <Table className="align-items-center table-flush" responsive hover>
                                                <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">Role</th>
                                                    <th scope="col">Start Date</th>
                                                    <th scope="col">End Date</th>
                                                    <th scope="col">Consent Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    officer && officer.roles && officer.roles.items.map(Role => (
                                                        <tr key={Role.id} onClick={() => this.onRoleClick(Role)}>
                                                            <td>{Role.title}</td>
                                                            <td>{Role.start_date}</td>
                                                            <td>{Role.end_date}</td>
                                                            <td><DocumentStatus documentID={Role.consent_document_id}/></td>
                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>


                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Officer;