import React, { useState, useEffect }from "react";
import {API, Auth, Storage} from 'aws-amplify';

import { listNotes } from '../../graphql/queries';
import Header from "../../components/Headers/Header";
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row} from "reactstrap";

import {
    createDocument as createDocumentMutation,
    createFile as createFileMutation
} from '../../graphql/mutations';
import Dropzone from "react-dropzone";
import CardsFooter from "../../components/Footers/CardsFooter";
import {Document} from "react-pdf";
import { v4 as uuidv4 } from 'uuid';
import SubmitButton from "../../components/CustomButtons/SubmitButton";
import {awsInteraction} from "../../util/graphQL";

class NewDocument extends React.Component {

    state = {
        documents: [],
        documentName: '',
        documentDescription: '',
        file: null,
        documentStatus: 'Complete',
        processingForm: false
    };

    constructor(props) {
        super(props);

        this.createDocument = this.createDocument.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async createDocument() {
        if (!this.state.documentName || !this.state.file) return;

        this.setState({processingForm: true});

        let documentName = this.state.documentName;
        let documentDescription = this.state.documentDescription;

        let fileName = this.state.file.name;
        let fileDescription = "First version";

        let documentResponse = await awsInteraction.createDocument(documentName, documentDescription);

        if(!documentResponse){
            console.log("Failed to create new document");
            this.setState({processingForm: false});
            return;
        }

        let fileResponse = await awsInteraction.createFile(fileName, fileDescription, this.state.file,
            this.state.documentStatus, documentResponse.data.createDocument.id);

        this.setState({processingForm: false});

        if(fileResponse){
            this.props.history.push('/documents');
        } else {
            console.log("Failed to create file ");
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        this.createDocument().catch(err => {
            console.log(err);
        });
    }

    render() {
        return (
            <>
                <Header />
                {/* Page content */}
                <Container className=" mt--7" fluid>
                    <Row>
                        <div className=" col">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <h3 className="mb-0">New Document</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={this.handleSubmit}>
                                        <Row>
                                            <Col>
                                                <h6 className="heading-small text-muted mb-4">
                                                    Document Description
                                                </h6>
                                                <div className="pl-lg-4">
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-username"
                                                                >
                                                                    Filename
                                                                </label>
                                                                <Input
                                                                    onChange={e => this.setState({documentName: e.target.value})}
                                                                    value={this.state.documentName}
                                                                    className="form-control-alternative"
                                                                    id="input-username"
                                                                    placeholder="filename"
                                                                    type="text"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-email"
                                                                >
                                                                    Description
                                                                </label>
                                                                <Input
                                                                    onChange={e => this.setState({documentDescription: e.target.value})}
                                                                    value={this.state.documentDescription}
                                                                    className="form-control-alternative"
                                                                    id="input-email"
                                                                    placeholder="description"
                                                                    type="text"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="documentStatusDropdown"
                                                                >
                                                                    Document Status
                                                                </label>
                                                                <Input
                                                                    onChange={e => this.setState({documentStatus: e.target.value})}
                                                                    id="documentStatusDropdown"
                                                                    type="select">
                                                                    <option>Complete</option>
                                                                    <option>Need Signature</option>
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col>
                                                {/* Description */}
                                                <h6 className="heading-small text-muted mb-4">Document Upload</h6>
                                                <div className="pl-lg-4">
                                                    <FormGroup>
                                                        <label>Document Upload</label>
                                                        {!this.state.file &&
                                                        <Dropzone
                                                            onDrop={acceptedFiles => this.setState({file: acceptedFiles[0]})}
                                                            maxFiles={1} accept={'.pdf'}>
                                                            {({getRootProps, getInputProps}) => (
                                                                <section>
                                                                    <div {...getRootProps()}>
                                                                        <input {...getInputProps()} />
                                                                        <p>Drag 'n' drop some files here, or click to
                                                                            select files</p>
                                                                    </div>
                                                                </section>
                                                            )}
                                                        </Dropzone>
                                                        }
                                                        {this.state.file &&
                                                        <div>
                                                            <p>{this.state.file.name}</p>
                                                            <Button
                                                                color="primary"
                                                                size="sm"
                                                                onClick={e => (this.setState({file: null}))}
                                                            >
                                                                Remove
                                                            </Button>
                                                        </div>
                                                        }
                                                    </FormGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                        <SubmitButton
                                            waiting={this.state.processingForm}
                                        >
                                            Submit
                                        </SubmitButton>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default NewDocument;