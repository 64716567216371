export const listDocumentsAndFiles = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        files {
          items{
            id
            name
            description
            s3_location    
            status      
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getPersonnelAndRoles = /* GraphQL */ `
  query GetPersonnel($id: ID!) {
    getPersonnel(id: $id) {
      id
      name
      former_name
      address
      date_of_birth
      country_of_birth
      start_date
      end_date
      createdAt
      updatedAt
      documents {
        items {
          id
          parentID
          name
          description
          document_type
          createdAt
          updatedAt
        }
        nextToken
      }
      roles {
        items {
          id
          title
          start_date
          end_date
          personnelID
          consent_document_id
          consent_document {
            items {
              id
              parentID
              name
              description
              document_type
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPersonnelsAndRoles = /* GraphQL */ `
  query ListPersonnels(
    $filter: ModelPersonnelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonnels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        former_name
        address
        date_of_birth
        country_of_birth
        start_date
        end_date
        createdAt
        updatedAt
        documents {
          nextToken
        }
        roles {
            items {
              id
              title
              start_date
              end_date
              personnelID
              consent_document_id
              consent_document {
                items {
                  id
                  parentID
                  name
                  description
                  document_type
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
      }
      nextToken
    }
  }
`;
