import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    companyName: {
        flexDirection: 'row',
        marginTop: 36,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    fileDetails: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
            fontSize: 12,
            fontStyle: 'bold',
    },
    label: {
        width: 120
    }
  });


  const IssueCompanyDetails = ({shareCertificateDetails}) => (
        <Fragment>
            <View style={styles.companyName}>
                <Text style={styles.invoiceDate}>{shareCertificateDetails.issueCompanyName}</Text>
            </View >
            <View style={styles.companyName}>
                <Text style={styles.invoiceDate}>A.C.N.: {shareCertificateDetails.acn}</Text>
            </View >
            <View style={styles.fileDetails}>
                <Text style={styles.label}>Certificate Number: </Text>
                <Text >{shareCertificateDetails.acn}</Text>
            </View >
            <View>
                <Text style={styles.label}>Registered Office: </Text>
                <Text >{shareCertificateDetails.address}</Text>
            </View >
        </Fragment>
  );
  
  export default IssueCompanyDetails