import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import DirectorTableHeader from "./DirectorTableHeader";
import DirectorTableRow from "./DirectorTableRow";
import DirectorTableFooter from "./DirectorTableFooter";

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
});

  const DirectorItemsTable = ({directors}) => (
    <View style={styles.tableContainer}>
        <DirectorTableHeader />
        <DirectorTableRow items={directors.items} />
    </View>
  );
  
  export default DirectorItemsTable