import React from "react";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/cjs/Button";

class FileUpload extends React.Component{
    state = {
        filename: "Upload Pdf File"
    }

    handleChange(e){
        this.props.onFileChange(e.target.files[0]);
        this.setState({filename: e.target.files[0].name});
    }

    render(){
        return(
            <div className="custom-file">
                <input
                    className="custom-file-input"
                    id="customFileLang"
                    lang="en"
                    type="file"
                    disabled={this.props.disabled}
                    onChange={event => {
                        this.handleChange(event);
                    }}
                />
                <label
                    className="custom-file-label"
                    htmlFor="customFileLang"
                >
                    {this.state.filename}
                </label>
            </div>
        )
    }
}

export default FileUpload;