import React, { Component, Suspense } from 'react';
import {Switch, Route, Router} from 'react-router-dom';
import Aux from "../hoc/_Aux";
import ScrollToTop from "./layout/ScrollToTop";

import { history } from '../_helpers/history';
import {AuthPrivateRoute} from "./auth/AuthPrivateRoute/AuthPrivateRoute";
import Login from "../views/auth/Login";
import Admin from "./layout/Admin";
import Loader from "App/layout/Loader";

import aws_config from "./../aws-exports";

import { connect } from 'react-redux';
import {Authenticator, withAuthenticator} from "aws-amplify-react";
import CreateAccount from "../views/auth/CreateAccount";
import ForgotPassword from "../views/auth/ForgotPassword";

class App extends Component {
    constructor(props) {
        super(props);

        /*
        const { dispatch } = this.props;

        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
         */
    }

    render() {
        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader/>}>
                        <Router history={history}>
                            <div>
                                <Switch>
                                    <Route exact path="/login" component={Login} />
                                    <Route exact path="/register" component={CreateAccount} />
                                    <Route exact path="/reset_password" component={ForgotPassword} />
                                    <AuthPrivateRoute path="/" component={Admin} />
                                </Switch>
                            </div>
                        </Router>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedApp = connect(mapStateToProps)(App);
export {connectedApp as App};