import React from 'react';
import {NavLink} from 'react-router-dom';

//import '../../assets/scss/style.scss';
import Aux from "../../hoc/_Aux";
import Breadcrumb from "../Breadcrumb";

import {connect} from "react-redux";
import Alert from "react-bootstrap/Alert";

class ResetPassword extends React.Component {
    state = {

    }

    constructor(props) {
        super(props);

        console.log(props.location.pathname.split('/'));

        let location = props.location.pathname.split('/');

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state.uid = location[2];
        this.state.token = location[3];
        this.state.password = "";
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { password,uid,token } = this.state;
        const { dispatch } = this.props;
        //TODO: Send reset action
        /*
        if (password && uid && token) {
            dispatch(authActions.resetPassword(uid,token,password));
        }
         */
    }

    render () {
        const { authentication } = this.props;
        const { password } = this.state;

        return(
            <Aux>
                <Breadcrumb/>
                <div className="auth-wrapper aut-bg-img-side cotainer-fiuid align-items-stretch">
                    <div className="row align-items-center w-100 align-items-stretch bg-white">
                        <div className="col-lg-4 align-items-stret h-100 align-items-center d-flex justify-content-center">
                            <form onSubmit={this.handleSubmit} className=" auth-content text-center">
                                <div className="mb-4">
                                    <i className="feather icon-unlock auth-icon"/>
                                </div>
                                <h3 className="mb-4">Reset Password</h3>
                                {authentication.message &&
                                <Alert variant={`primary`}>
                                    <p>{authentication.message}</p>
                                    <a href="/login">Return to Login</a>
                                </Alert>
                                }
                                <div className="input-group mb-3">
                                    <input type="password" className="form-control" placeholder="New Password"
                                           name="password"
                                           onChange={this.handleChange}
                                           value={password}/>
                                </div>
                                <input className="btn btn-primary shadow-2 mb-4" type='submit' name='Change Password' value='Change Password'/>
                            </form>
                        </div>
                    </div>
                </div>
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication } = state;
    return {
        alert,
        authentication
    };
}

const connectedLoginPage = connect(mapStateToProps)(ResetPassword);
export { connectedLoginPage as ResetPassword };