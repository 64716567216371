import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import MemberTableHeader from "./MemberTableHeader";
import MemberTableRow from "./MemberTableRow";

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
});

  const MemberItemsTable = ({directors}) => (
    <View style={styles.tableContainer}>
        <MemberTableHeader />
        <MemberTableRow items={directors.items} />
    </View>
  );
  
  export default MemberItemsTable