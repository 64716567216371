import React, { useState, useEffect }from "react";
import { API, Storage } from 'aws-amplify';

import Header from "../components/Headers/Header";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, DropdownItem, DropdownMenu, DropdownToggle,
    Media, Progress,
    Row,
    Table, UncontrolledDropdown,
    UncontrolledTooltip
} from "reactstrap";
import {getDocument} from "../graphql/queries";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import {displayAWSDate, displayAWSDateTime} from "../util/time";
import {Link} from "react-router-dom";

class document extends React.Component {

    state = {
        Document: null,
        downloadPath: ''
    };

    constructor(props) {
        super(props);

        this.fetchDocument = this.fetchDocument.bind(this);

        this.fetchDocument().catch(err => {
            console.log('Could not fetch Documents', err);
        });
    }

    async fetchDocument() {
        const apiData = await API.graphql({
            query: getDocument,
            variables: {
                id: this.props.match.params.id
            }
        });

        const DocumentFromAPI = apiData.data.getDocument;

        this.setState({apiData: apiData.data, Document: DocumentFromAPI})

        let files = DocumentFromAPI.files.items;

        if(files.length > 0) {
            this.onFileClick(files[files.length - 1])
        }
    }

    async onFileClick(file){
        let pdf_file = await Storage.vault.get(file.s3_location, {download: true});

        let arrayPromise = new Promise(function(resolve) {
            let reader = new FileReader();

            reader.onloadend = function() {
                resolve(reader.result);
            };

            reader.readAsArrayBuffer(pdf_file.Body);
        });

        let a = await arrayPromise;

        Storage.vault.get(file.s3_location).then(r => {
            this.setState({downloadPath: r})
        })

        this.setState({file: a, fileLocation: file.s3_location, selectedFile: file});
    }

    render() {
        return (
            <>
                <Header />
                {/* Page content */}
                <Container className=" mt--7" fluid>
                    <Row>
                        <div className=" col">
                            <Card className=" shadow">
                                <CardHeader className=" bg-transparent">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Document Name</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <Button
                                                color="primary"
                                                size="sm"
                                            >
                                                <Link
                                                    className="button-link"
                                                    to={this.props.match.params.id + "/new"}
                                                >New Version</Link>
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <Table className="align-items-center table-flush" responsive hover>
                                                <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">File Name</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Updated Time</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.Document && this.state.Document.files.items.map(File => (
                                                        <tr key={File.id} onClick={() => this.onFileClick(File)}>
                                                            <td>{File.name}</td>
                                                            <td>{File.description}</td>
                                                            <td>{displayAWSDateTime(File.updatedAt)}</td>
                                                            <td>{File.status}</td>
                                                            <td></td>
                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col>
                                            {this.state.file &&
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <h3>Previewing</h3>
                                                    </Col>
                                                    <Col className="text-right" xs="4">
                                                        <Button
                                                            color="primary"
                                                            href={this.state.downloadPath}
                                                            size="sm"
                                                        >
                                                            Download
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Document file={this.state.file}>
                                                    <Page pageNumber={1} />
                                                </Document>

                                            </div>
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default document;