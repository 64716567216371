/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/auth/Login.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import notepad from "./views/test/notepad";
import documents from "./views/documents";
import company from "./views/company";
import Contractors from "./views/Contractors";
import Customers from "./views/Customers";
import Templates from "./views/Templates";

let sidebar_routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    exact: true,
    icon: "ni ni-tv-2 text-primary",
    component: Index
  },
  {
    path: "/documents",
    name: "Documents",
    exact: true,
    icon: "ni ni-bullet-list-67 text-red",
    component: documents
  },
  {
    path: "/company",
    name: "Company",
    exact: true,
    icon: "ni ni-bullet-list-67 text-red",
    component: company
  },
];
export default sidebar_routes;

/*

  {
    path: "/contractors",
    name: "Contractors",
    exact: true,
    icon: "ni ni-bullet-list-67 text-red",
    component: Contractors
  },
  {
    path: "/customers",
    name: "Customers",
    exact: true,
    icon: "ni ni-bullet-list-67 text-red",
    component: Customers
  },
  {
    path: "/templates",
    name: "Templates",
    exact: true,
    icon: "ni ni-bullet-list-67 text-red",
    component: Templates
  }

 */