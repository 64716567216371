const shareCertificateDetails = {
    "id": "5df3180a09ea16dc4b95f910",
    "issueCompanyName": "Temp Company Name",
    "balance": "$2,283.74",
    "receivingCompany": "MANTRIX",
    "email": "susanafuentes@mantrix.com",
    "phone": "+1 (872) 588-3809",
    "address": "922 Campus Road, Drytown, Wisconsin, 1986",
    "acn": "123 456 789",
    "due_date": "2019-10-12",
    "items": [
      {
        "sno": 1,
        "desc": "O",
        "qty": 1000,
        "rate": 10
      }
    ]
  }
  
  export default shareCertificateDetails