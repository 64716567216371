import React from 'react';

//import '../../assets/scss/style.scss';
import Aux from "../../hoc/_Aux";
import Breadcrumb from "../Breadcrumb";

import {connect} from "react-redux";
import Alert from "react-bootstrap/Alert";

class RequestResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { email } = this.state;
        const { dispatch } = this.props;

        // TODO: Send reset action
        /*
        if (email) {
            dispatch(authActions.resetPasswordEmail(email));
        }
         */
    }

    render () {
        const { authentication } = this.props;
        const { email } = this.state;

        return(
            <Aux>
                <Breadcrumb/>
                <div className="auth-wrapper aut-bg-img-side cotainer-fiuid align-items-stretch">
                    <div className="row align-items-center w-100 align-items-stretch bg-white">
                        <div className="col-lg-4 align-items-stret h-100 align-items-center d-flex justify-content-center">
                            <form onSubmit={this.handleSubmit} className=" auth-content text-center">
                                <div className="mb-4">
                                    <i className="feather icon-unlock auth-icon"/>
                                </div>
                                <h3 className="mb-4">Reset Password</h3>
                                {authentication.message &&
                                    <Alert variant={`primary`}>{authentication.message}</Alert>
                                }
                                <div className="input-group mb-3">
                                    <input type="email" className="form-control" placeholder="Email"
                                           name="email"
                                           onChange={this.handleChange}
                                           value={email}/>
                                </div>
                                <input className="btn btn-primary shadow-2 mb-4" type='submit' name='Send Email' value='Send Email'/>
                            </form>
                        </div>
                    </div>
                </div>
            </Aux>
        );
    }
}


function mapStateToProps(state) {
    const { alert, authentication } = state;
    return {
        alert,
        authentication
    };
}

const connectedLoginPage = connect(mapStateToProps)(RequestResetPassword);
export { connectedLoginPage as RequestResetPassword };