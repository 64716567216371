import React, { useState, useEffect }from "react";
import {API, Auth, Storage} from 'aws-amplify';

import {listDocumentsAndFiles} from "../graphql/custom_queries";
import Header from "../components/Headers/Header";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, DropdownItem, DropdownMenu, DropdownToggle,
    Media, Nav, Progress,
    Row,
    Table, UncontrolledDropdown,
    UncontrolledTooltip
} from "reactstrap";
import {displayAWSDate, displayAWSDateTime} from "../util/time";
import DocumentStatus from "../components/DocumentStatus";
import {Link} from "react-router-dom";

class documents extends React.Component {

    state = {
        Documents: [],
        DocumentName: '',
        DocumentDescription: '',
    };

    constructor(props) {
        super(props);

        this.fetchDocuments = this.fetchDocuments.bind(this);

        this.fetchDocuments().catch(err => {
            console.log('Could not fetch Documents', err);
        });
    }

    async fetchDocuments() {
        const apiData = await API.graphql({ query: listDocumentsAndFiles });
        const DocumentsFromAPI = apiData.data.listDocuments.items;

        await Promise.all(DocumentsFromAPI.map(async Document => {
            if (Document.image) {
                const image = await Storage.vault.get(Document.image);
                Document.image = image;
            }
            return Document;
        }))

        this.setState({apiData: apiData.data, Documents: DocumentsFromAPI})
    }

    onDocumentClick(Document){
        this.props.history.push('/document/'+Document.id);
    }

    render() {
        return (
            <>
                <Header />
                {/* Page content */}
                <Container className=" mt--7" fluid>
                    <Row>
                        <div className=" col">
                            <Card className="shadow">
                                <CardHeader className=" bg-transparent">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">All Documents</h3>
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            <Button
                                                color="primary"
                                                size="sm"
                                            >
                                                <Link
                                                    className="button-link"
                                                    to="/new-document"
                                                >New Document</Link>
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Table className="align-items-center table-flush" responsive hover>
                                        <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Document Name</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Last Updated</th>
                                            <th scope="col">Versions</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.Documents.map(Document => (
                                                <tr key={Document.id}>
                                                    <td onClick={() => this.onDocumentClick(Document)}>{Document.name}</td>
                                                    <td onClick={() => this.onDocumentClick(Document)}>{Document.description}</td>
                                                    <td onClick={() => this.onDocumentClick(Document)}>{displayAWSDateTime(Document.updatedAt)}</td>
                                                    <td onClick={() => this.onDocumentClick(Document)}>{Document.files.items.length} Version(s)</td>
                                                    <td onClick={() => this.onDocumentClick(Document)}><DocumentStatus files={Document.files.items} /></td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        );
    }
}

export default documents;